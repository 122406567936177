import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const LanguageContext = createContext();

// Create a provider for components to consume and update the language
export const LanguageProvider = ({ children }) => {
    const storedLanguage = localStorage.getItem('language');
    const initialLanguage = storedLanguage ? storedLanguage : 'en'; // Default to 'en' if no language is stored

    const [language, setLanguage] = useState(initialLanguage);

    // Update localStorage when language changes
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
