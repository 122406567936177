import React from 'react';
//import axios from 'axios'; // Import axios for making API calls
import './TermsAndConditions.css'; // Import custom CSS for styling
//import { Link } from 'react-router-dom';
import './LearnMore.css';

function LearnMore() {

    const faqs = [
        {
            question: 'What is Gwow AI and how does it work?',
            answer: 'Gwow AI is a cutting-edge platform that utilizes artificial intelligence to create personalized storybooks for children. It works by analyzing user preferences and input to generate unique, engaging stories tailored to the child�s interests, reading level, and educational needs. The AI ensures that each story is not only entertaining but also age-appropriate and educationally enriching.'
        },
        {
            question: 'What makes Gwow AI unique compared to other storytelling platforms?',
            answer: 'Gwow AI stands out due to its advanced personalization capabilities and interactive features. Unlike many platforms, Gwow AI provides a deeply customized storytelling experience by using AI to craft stories that reflect individual preferences and learning goals. While users cannot edit the generated stories directly, the platform allows for significant customization to meet specific needs.'
        },
        {
            question: 'Is Gwow AI available in multiple languages?',
            answer: 'Yes, Gwow AI supports multiple languages, making it accessible to a diverse global audience. Users can select from a variety of languages to create and enjoy personalized stories, which helps in promoting inclusivity and reaching children from different linguistic backgrounds.'
        },
        {
            question: 'Can Gwow AI generate stories based on specific themes or educational goals?',
            answer: 'Absolutely. Gwow AI allows parents to select specific themes and educational goals when generating stories. This means that stories can be tailored to include particular subjects, moral lessons, or favorite hobbies, providing a targeted educational experience that aligns with the child�s interests and learning objectives.'
        },
        {
            question: 'What role does storytelling play in a child\'s education?',
            answer: 'Storytelling is a powerful educational tool that supports language development, comprehension, and critical thinking. It encourages creativity and helps children understand complex concepts and emotions through engaging narratives. Personalized stories from Gwow AI enhance this process by aligning with the child�s individual learning needs and interests.'
        },
        {
            question: 'How can AI-generated stories benefit a child\'s development and learning?',
            answer: 'AI-generated stories can significantly benefit a child\'s development by providing tailored content that captivates their interest and supports their learning. Personalized stories can improve literacy skills, stimulate imagination, and offer interactive learning experiences that are aligned with the child\'s developmental stage and educational goals.'
        },
        {
            question: 'Can Gwow AI help children with different learning styles?',
            answer: 'Yes, Gwow AI is designed to accommodate various learning styles. By customizing stories to fit visual, auditory, and kinesthetic learning preferences, the platform ensures that content is engaging and effective for children with different educational needs and styles.'
        },
        {
            question: 'What are the benefits of reading personalized stories to children?',
            answer: 'Personalized stories can enhance a child\'s engagement and comprehension by reflecting their unique interests and experiences. These tailored narratives make reading more enjoyable and meaningful, fostering a love for literature and improving educational outcomes.'
        },
        {
            question: 'What are some effective parenting strategies for promoting positive behavior while balancing discipline and affection?',
            answer: 'Effective parenting strategies include setting clear expectations, using positive reinforcement, and maintaining consistent routines. Balancing discipline with affection involves enforcing rules while showing love and support. Gwow AI can assist in this process by providing stories that reinforce positive behavior and educational values in an engaging manner.'
        },
        {
            question: 'How can parents support their child\'s emotional well-being?',
            answer: 'Parents can support emotional well-being by creating a supportive environment where children feel comfortable expressing their feelings. Managing stress can be enhanced by using Gwow AI-generated stories to simulate and address various emotional situations, helping children understand and manage their emotions in a safe and engaging way.'
        },
        {
            question: 'What role does education play in preparing children for the future?',
            answer: 'Education is crucial for equipping children with the knowledge, skills, and critical thinking abilities needed for future success. Gwow AI contributes by providing educational stories that align with academic goals and developmental milestones, preparing children for future learning and personal growth.'
        },
        {
            question: 'What are common signs of speech delays in children and how can parents support a child?',
            answer: 'Common signs of speech delays include difficulty pronouncing words, limited vocabulary, and challenges in forming sentences. Parents can support their child by engaging in regular language activities, using Gwow AI to create easy-to-read books and coloring pages, and incorporating favorite characters to encourage pronunciation practice.'
        },
        {
            question: 'How can parents effectively manage screen time for their children and can technology be used to support family bonding?',
            answer: 'Parents can manage screen time by setting limits, ensuring content is appropriate, and balancing tech use with other activities. Gwow AI can be part of this balance by serving as a tool for family bonding through shared storytelling sessions, creating opportunities for interactive and enjoyable experiences together.'
        },
        {
            question: 'What should parents look for in a quality storytelling platform for their children?',
            answer: 'Parents should seek platforms that offer age-appropriate content, customization options, educational value, and secure data practices. Gwow AI meets these criteria by providing personalized, engaging stories that align with educational goals while ensuring a safe and user-friendly experience.'
        },
        {
            question: 'How can storytelling help children manage emotions and build empathy?',
            answer: 'Storytelling helps children understand and process emotions by presenting relatable scenarios and diverse characters. Gwow AI\'s personalized stories can enhance this by reflecting the child\'s experiences and emotions, promoting empathy and emotional intelligence through engaging narratives.'
        },
        {
            question: 'What are some strategies for incorporating storytelling into daily routines?',
            answer: 'Strategies include setting aside regular times for reading, choosing stories that align with daily themes or lessons, and involving children in the storytelling process. Gwow AI can be used to generate stories that fit specific themes or educational objectives, making storytelling an integral part of daily routines.'
        },
        {
            question: 'What are the signs that a child is developing a strong interest in reading?',
            answer: 'Signs include frequent requests for books, eagerness to participate in reading activities, and showing enthusiasm for story-related discussions. A strong interest in reading is often marked by the child\'s active engagement and curiosity about books and stories.'
        },
        {
            question: 'How does exposure to diverse stories impact a child\'s worldview?',
            answer: 'Exposure to diverse stories helps children develop a broader understanding of different cultures, perspectives, and experiences. It fosters empathy, cultural awareness, and open-mindedness, contributing to a more inclusive and well-rounded worldview.'
        }
    ];

    return (
        <section className="learn-more-section">
            <h2>Learn More</h2>
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <h3 className="faq-question">{faq.question}</h3>
                    <p className="faq-answer">{faq.answer}</p>
                </div>
            ))}
        </section>
    );


}
export default LearnMore;
