import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Import axios for making API calls
import './StoryForm.css'; // Import custom CSS for styling
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import translations from './translations';
import jwt_decode from 'jwt-decode';

function StoryForm({ isLoggedIn }) {
    const [formData, setFormData] = useState({
        ageGroup: '',
        maincharacter: '',
        gender: '',
        length: '2',
        themes: '',
        lessonLearned: '',
        visualStyle: '',
        characterArchetypes: '',
        genre: '',
        plotElements: '',
        magicalElements: '',
        culturalContext: '',
        emotionalTone: '',
        environment: '',
        specificPreference: '',
        kidImage: null
    });
    
       // const flipbook = document.querySelector('.flipbook');
        const [generatedStory, setGeneratedStory] = useState([]);
        const [pageImages, setPageImages] = useState([]);
  
        const [generatedCombinedStory, setGeneratedCombinedStory] = useState([]);
        const [currentPairIndex, setCurrentPairIndex] = useState(0);
     //   const [currentPage, setCurrentPage] = useState(0);
        const [loading, setLoading] = useState(false);
        const [showInputForm, setShowInputForm] = useState(true);
        const [showSuccessMessage, setshowSuccessMessage] = useState(false);
        const [showFailMessage, setshowFailMessage] = useState(false);
        const [isStorySaved, setIsStorySaved] = useState(false);
  //  const [showBook, setShowBook] = useState(false);

   // const userId = localStorage.getItem("userId");




  //  const token = localStorage.getItem('token');
  //  console.log("we are inside the storyform, this is the toked", token);
   // const decodedToken = jwt_decode(token);

 //   console.log("inside storyform, is logged in status is : ", isLoggedIn);
    let decodedToken;
    let token;
    let userId;
    if (isLoggedIn) {
     
         token = localStorage.getItem('token');  // Get token from local storage

        if (token) {  // Check if token exists in local storage
            try {
                decodedToken = jwt_decode(token);  // Decode the token
                userId = decodedToken.id;
         //     console.log("Decoded Token:", decodedToken);

                // You can proceed with your logic using the decoded token data
            } catch (error) {
             //  console.error("Token decoding failed:", error.message);
                // Handle error if decoding fails (e.g., invalid or expired token)
            }
        } else {
       //   console.log("No token found in local storage");
            // Optionally handle case where no token exists (e.g., log out the user or redirect)
        }
    } else {
      //  console.log("User is not logged in");
        // Handle logic for users that are not logged in
    }






    const balance = localStorage.getItem('balance');

    const { language } = useContext(LanguageContext);

        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };

    

        // Function to save the generated story
    const saveStory = async (generatedStory, pageImages, language) => {
            setLoading(true);
       // console.log("savestory diplaying language inside savestory called ", language);
            try {
             
                const response = await axios.post('/api/story/savestory', {
                    generatedStory,
                    pageImages,
                    language
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
             //   console.log("trying to print response after saving ", response.data);
                if (response.data) {
               //     console.log("we are inside the if statement ");
                    setShowInputForm(false);
                    setLoading(false);
                    setshowSuccessMessage(true);
                    setIsStorySaved(true);
                 //   setShowBook(false);

               
                    // Hide the message after a certain time (e.g., 3 seconds)
                    setTimeout(() => {
                        setshowSuccessMessage(false);
                    }, 5000);
                    return true;
                }

                else {
                  //  console.log("we are inside the else statement ");
                    setIsStorySaved(false);
                    setshowFailMessage(true);
                    setShowInputForm(false); 
                       setLoading(false);
                    // Hide the message after a certain time (e.g., 3 seconds)
                    setTimeout(() => {
                        setshowFailMessage(false);
                      //  setShowBook(true);
                    }, 5000);
                    return false;
               

                }
            
                // Handle response if needed
            } catch (error) {
                console.error('Error saving story:', error);
                setLoading(false);
                setshowFailMessage(true);
                setTimeout(() => {
                    setshowFailMessage(false);
                }, 5000);
                return false;
            }

        };
    /*
    const updateBalance = async (newBalance) => {
        try {
           // console.log("inside the updatebalance funciotn, balance receis is ",newBalance);
          //  await axios.post('/api/story/updateBalance', { userId, newBalance });
            localStorage.setItem('balance', newBalance);
        } catch (error) {
            console.error('Error updating balance:', error);
        }
    };*/



        const handleSubmit = async (e) => {  
           e.preventDefault();
            setLoading(true);

            if (!token) {
                console.error('No token found. Cannot submit the story.');
                setLoading(false);
                return;
            }

            try {
                const language = formData.language;
            //   console.log("trying generating story");
                const dataToSend = { ...formData, userId };

              
                const response = await axios.post('/api/story/generate', dataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });


              //  console.log("we came back from generatingggggggggggggggggggg");
               // console.log('Response from API:', response.data);
                if (response.data && response.data.story && response.data.pageImages) {
                 
              
                   const storyPages = splitStoryIntoPages(response.data.story, formData.length);
                   setGeneratedStory(storyPages);
                   const images = response.data.pageImages;
                   setPageImages(images);



                   // Call saveStory function after generating the story
                 
             //   console.log("logging the language being sent to savestory", language);
                   const saveResponse = await saveStory(storyPages, images, language);


                   /*
                   if (!saveResponse) {
                       const deductedbalance = response.data.newbalance;
                      // console.log("we are inside the saveresponse as saving story failed", deductedbalance);
                       // If saving the story failed, update the balance back
                       const adjustednewBalance = deductedbalance + 1; // Revert the balance deduction
                      // console.log("we are inside the saveresponse as saving story failed, new balance to be added ", adjustednewBalance);
                       await updateBalance(adjustednewBalance);
                   }
                   else {
                       const newbalance = response.data.newbalance;
                     //  console.log("checking the new balance from the database after creating", newbalance);
                       localStorage.setItem('balance', newbalance);
                       const checkbalance = localStorage.getItem('balance');
                     //  console.log("inside the story form checking new balance from local storage after retrieving from server newuser**********", checkbalance);
         }
*/
                   /*
                   if (saveResponse) {
                       const deductedBalance = response.data.newbalance - 1; // Deduct 1 from the new balance
                      // console.log("Saving story was successful. New balance to be updated:", deductedBalance);
                       await updateBalance(deductedBalance); // Update the balance
                       localStorage.setItem('balance', deductedBalance); // Update local storage
                       const checkBalance = localStorage.getItem('balance');
                      // console.log("Updated balance in local storage:", checkBalance);
                   } else {
                     //  console.log("Saving story failed. Balance remains unchanged.");
                   }*/
                   if (saveResponse) {
                   //   const newBalance = response.data.newbalance; // Get the updated balance from backend
                   

                     //  localStorage.setItem('balance', newBalance);

                       const storedBalance = localStorage.getItem('balance');
                       if (storedBalance !== null) {
                           const updatedBalance = parseInt(storedBalance, 10) - 1; // Deduct 1 from the balance
                           localStorage.setItem('balance', updatedBalance); // Update localStorage with new balance
                       }
                 
                   } else {
                //       console.log("Saving story failed. Balance remains unchanged.");
                   }


                   setShowInputForm(false);

                   //setShowBook(true);
                   //update the user's balance
                  
                }
               else {
                   throw new Error('Invalid response from server.');
               }
            } catch (error) {
              //  console.error('Error generating story:', error);
                // You can add this logging if you want to catch specific errors here:
                if (error.response) {
                    console.error('API responded with an error');
                } else if (error.request) {
                    console.error('No response received from the API' );
                } else {
                    console.error('Error setting up the request');
                }
                setTimeout(() => {
                    setshowFailMessage(false);
                }, 600000);
            } finally {
               setLoading(false);
            }
        };
    const mergeStoryAndImages = (story, pageImages) => {
        return story.map((page, index) => ({
            id: index + 1, // Adjust page_number to start from 1
            page_text: page.content, // Assuming content is the text property in each page object
            image_url: pageImages[index], // Assuming pageImages contain image URLs
        }));
    };

        useEffect(() => {
           
            if (generatedStory.length > 0 && pageImages.length > 0) {
         
                const mergedData = mergeStoryAndImages(generatedStory, pageImages);
              //  console.log("we are inside the use effect to update the merged data ahe", mergedData);
                setGeneratedCombinedStory(mergedData);
                applyPageLogic();
            }
        }, [generatedCombinedStory,generatedStory, pageImages]);
   

        const applyPageLogic = () => {

      //  console.log("trying to check where apply page logicccc the code reachs1");
        const pages = document.getElementsByClassName('page');
     //   console.log("trying to check where apply page logicccc the code reachs1, page length", pages.length);
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (i % 2 === 0) {
                page.style.zIndex = pages.length - i;
            }
            page.id = i + 1;
            page.addEventListener('click', flipPage);
        }

     //   console.log("trying to check where apply page logicccc the code reachs2");
    };

    const flipPage = function () {
      //  console.log("trying to check where flipppppp the code reachs1", this.id);
        if (this.id % 2 === 0) {
        //    console.log("trying to check where flipppppp the code reachs2");
            this.classList.remove('flipped');
            this.previousElementSibling.classList.remove('flipped');
            setCurrentPairIndex(currentPairIndex + 2);
        } else {
        //    console.log("trying to check where flipppppp the code reachs3");
            this.classList.add('flipped');
            this.nextElementSibling.classList.add('flipped');
            setCurrentPairIndex(currentPairIndex - 2);
        }
    };



    const splitStoryIntoPages = (story, bookLength) => {
        const pages = [];
        const sentences = story.split(/(?<=[.!?])/); // Split the story into an array of sentences
        const numSentences = sentences.length;
        const sentencesPerPage = Math.ceil(numSentences / bookLength);

        let startIndex = 0;
        let endIndex = sentencesPerPage;

        for (let i = 0; i < bookLength; i++) {
            const segment = sentences.slice(startIndex, endIndex).join(' ');
            if (segment.length > 0) {
                pages.push({ pageNumber: i + 1, content: segment }); // Store page content as an object
            }
            startIndex = endIndex;
            endIndex = Math.min(endIndex + sentencesPerPage, numSentences);
        }

        return pages;
    };

    /*
    const nextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, generatedStory.length - 1));
    };

    const previousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

  

    const createAnotherStory = () => {
        setShowInputForm(true);
        setGeneratedStory([]);
        setPageImages([]);
        setCurrentPage(0);
        setShowBook(false);
      //  console.log("weare indie the create another story, checking on the generated story", generatedStory);
    };
    function closeMessage() {
        var message = document.getElementById("messageForFlipPhones");
        message.style.display = "none";
    };*/

    const selectedLanguage = localStorage.getItem('language'); // Retrieve from localStorage

    const textAlignStyle = selectedLanguage === 'ar' ? 'right' : 'left';


    return (
        <div id={loading ? "" : ""}>

            {isLoggedIn ? (

                <div className="book-container" style={{ textAlign: textAlignStyle }}>
                  
                    {balance > 0 || generatedStory.length > 0 ? (
                        <>
                    {loading ? (
                                <section class="section">
                                  
                                    <div className="loader-container" >
                                        
                                        <div className="loading-font">{translations[language].pleasebarewithus}</div>
                                        <div class="loading">
                                            <span>{translations[language].loading}</span>
                                        </div>

                                        <div id="gamecontent">
                                    <form id="tictactoe">
                                        <input type="radio" name="cell-0" id="cell-0-x" />
                                        <input type="radio" name="cell-0" id="cell-0-o" />
                                        <input type="radio" name="cell-1" id="cell-1-x" />
                                        <input type="radio" name="cell-1" id="cell-1-o" />
                                        <input type="radio" name="cell-2" id="cell-2-x" />
                                        <input type="radio" name="cell-2" id="cell-2-o" />
                                        <input type="radio" name="cell-3" id="cell-3-x" />
                                        <input type="radio" name="cell-3" id="cell-3-o" />
                                        <input type="radio" name="cell-4" id="cell-4-x" />
                                        <input type="radio" name="cell-4" id="cell-4-o" />
                                        <input type="radio" name="cell-5" id="cell-5-x" />
                                        <input type="radio" name="cell-5" id="cell-5-o" />
                                        <input type="radio" name="cell-6" id="cell-6-x" />
                                        <input type="radio" name="cell-6" id="cell-6-o" />
                                        <input type="radio" name="cell-7" id="cell-7-x" />
                                        <input type="radio" name="cell-7" id="cell-7-o" />
                                        <input type="radio" name="cell-8" id="cell-8-x" />
                                        <input type="radio" name="cell-8" id="cell-8-o" />

                                        <div id="board" class="center">
                                            <div class="tile" id="tile-0">
                                                <label for="cell-0-x"></label>
                                                <label for="cell-0-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-1">
                                                <label for="cell-1-x"></label>
                                                <label for="cell-1-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-2">
                                                <label for="cell-2-x"></label>
                                                <label for="cell-2-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-3">
                                                <label for="cell-3-x"></label>
                                                <label for="cell-3-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-4">
                                                <label for="cell-4-x"></label>
                                                <label for="cell-4-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-5">
                                                <label for="cell-5-x"></label>
                                                <label for="cell-5-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-6">
                                                <label for="cell-6-x"></label>
                                                <label for="cell-6-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-7">
                                                <label for="cell-7-x"></label>
                                                <label for="cell-7-o"></label>
                                                <div></div>
                                            </div>
                                            <div class="tile" id="tile-8">
                                                <label for="cell-8-x"></label>
                                                <label for="cell-8-o"></label>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div id="end">
                                            <div id="message" class="center">
                                                <div>
                                                    <input type="reset" for="tictactoe" value="Play again" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                            </div>





                                </div>

                                    </section>
                            ) : showInputForm ? (


                                    <form onSubmit={handleSubmit} className="input-form">

                                        <p id="balance-text">{translations[language].booksremaining}{balance}</p>


                                        <h1 id="gnerallibraryoptionsheaderstoryform">{translations[language].createnewbook}</h1>

                                        <label>
                                            {translations[language].agegroup}
                                            <select name="ageGroup" value={formData.ageGroup} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectagegroup}</option>
                                                <option value="2">0-3 {translations[language].years}</option>
                                                <option value="4">3-5 {translations[language].years}</option>
                                                <option value="7">5-8 {translations[language].years}</option>
                                                <option value="9">8-10 {translations[language].years}</option>
                                                <option value="11">10-12 {translations[language].years}</option>
                                                <option value="15">13+ {translations[language].years}</option>
                                </select>
                                        </label>

                                        <label>
                                            {translations[language].maincharacter}
                                            <select name="maincharacter" value={formData.maincharacter} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectmainchacter}</option>
                                                <option value="child">{translations[language].child}</option>
                                                <option value="Superhero">{translations[language].superhero}</option>
                                                <option value="Animal">{translations[language].animal}</option>
                                                <option value="wizard">{translations[language].wizard}</option>
                                                <option value="Fairy">{translations[language].fairy}</option>
                                                <option value="Robot">{translations[language].robot}</option>
                                                <option value="Fantacy Characters">{translations[language].fantasycharacters}</option>
                                                <option value="">{translations[language].otherspleasespecifybelow}</option>




                                            </select>
                                        </label>




                            <label>
                                            {translations[language].gender}
                                            <select name="gender" value={formData.gender} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectgender}</option>
                                                <option value="Male">{translations[language].male}</option>
                                                <option value="Female">{translations[language].female}</option>
                                                <option value="Non-binary">{translations[language].unspecified}</option>
                                </select>
                            </label>
                            <label>
                                            {translations[language].booklength}
                                            <select name="length" value={formData.length} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="2">{translations[language].selectbooklength}</option> 
                                                <option value="2">4 {translations[language].pages}</option>
                                                <option value="3">6 {translations[language].pages}</option>
                                                <option value="4">8 {translations[language].pages}</option>
                                              
                                </select>
                            </label>
                            <label>
                                            {translations[language].morallesson}
                                            <select name="lessonLearned" value={formData.lessonLearned} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectmorallesson}</option>
                                                <option value="Friendship">{translations[language].friendship}</option>
                                                <option value="Kindness">{translations[language].kindness}</option>
                                                <option value="Courage">{translations[language].courage}</option>
                                                <option value="Honesty">{translations[language].honesty}</option>
                                                <option value="Empathy">{translations[language].empathy}</option>
                                                <option value="Teamwork">{translations[language].teamwork}</option>
                                                <option value="Acceptance">{translations[language].acceptance}</option>
                                                <option value="Responsibility">{translations[language].responsibility}</option>
                                                <option value="Creativity">{translations[language].creativity}</option>
                                </select>
                            </label>
                       
                          
                       
                           
                       
                            <label>
                                             {translations[language].addatwist}
                                            <select name="magicalElements" value={formData.magicalElements} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectmagicalelement} </option>
                                                
                                                <option value="Wizards">{translations[language].wizards}</option>
                                                <option value="mermaids">{translations[language].mermaids}</option>
                                                <option value="dinosaurs">{translations[language].dinosaurs}</option>
                                                <option value="robots">{translations[language].robots}</option>
                                                <option value="cars">{translations[language].cars}</option>
                                                <option value="pets">{translations[language].pets}</option>
                                                <option value="dragons">{translations[language].dragons}</option>
                                                <option value="aliens">{translations[language].aliens}</option>
                                                <option value="fairies">{translations[language].fairies}</option>
                                                <option value="superheroes">{translations[language].superheroes}</option>
                                                <option value="pirates">{translations[language].pirates}</option>
                                                <option value="cowboys">{translations[language].cowboys}</option>
                                                <option value="secret worlds">{translations[language].secretworlds}</option>
                                                <option value="Magical Creatures">{translations[language].magicalcreatures}</option>
                                                <option value="knights">{translations[language].knights}</option>
                                                <option value="princesses">{translations[language].princesses}</option>
                                                <option value="">{translations[language].otherspleasespecifybelow}</option>

                                            </select>
                            </label>
                            <label>
                                              {translations[language].cluturalcontext}
                                            <select name="culturalContext" value={formData.culturalContext} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectculturalcontext }</option>
                                                <option value="Ancient Egypt">{translations[language].ancientegypt}</option>
                                                <option value="Medieval Europe">{translations[language].medievaleurope}</option>
                                                <option value="Feudal Japan">{translations[language].feudaljapan}</option>
                                                <option value="Victorian England">{translations[language].victorianengland}</option>
                                                <option value="Renaissance Italy">{translations[language].renaissanceitaly}</option>
                                                <option value="Native American folklore">{translations[language].nativeamericanfolklore}</option>
                                                <option value="African mythology">{translations[language].africanmythology}</option>
                                                <option value="Asian legends">{translations[language].asianlegends}</option>
                                                <option value="Wild West">{translations[language].wildwest}</option>
                                </select>
                            </label>
                      
                            <label>
                                            {translations[language].environment}
                                            <select name="environment" value={formData.environment} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectenvironment}</option>
                                                <option value="Forest">{translations[language].forest}</option>
                                                <option value="Cityscape">{translations[language].cityscape}</option>
                                                <option value="Countryside">{translations[language].countryside}</option>
                                                <option value="Mountains">{translations[language].mountains}</option>
                                                <option value="Beach">{translations[language].beach}</option>
                                                <option value="Underwater Kingdom">{translations[language].underwaterkingdom}</option>
                                                <option value="Enchanted Forest">{translations[language].enchantedforest}</option>
                                                <option value="Magical Castle">{translations[language].magicalcastle}</option>
                                                <option value="Desert">{translations[language].desert}</option>
                                                <option value="Arctic Tundra">{translations[language].arctictundra}</option>
                                                <option value="Volcanic Island">{translations[language].volcanicisland}</option>
                                                <option value="Alien Planet">{translations[language].alienplanet}</option>
                                </select>
                                        </label>

                                        <label>
                                            {translations[language].Whatillustrationstyle}
                                            <select name="visualStyle" value={formData.visualStyle} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                                <option value="">{translations[language].selectone}</option>
                                                <option value="Cartoon">{translations[language].cartoon}</option>
                                                <option value="Anime or manga">{translations[language].anime}</option>
                                              

                                                <option value="3D/CGI">3D/CGI</option>
                                                <option value="Realistic">{translations[language].realistic}</option>



                                            </select>
                                        </label>


                                        <label>
                                            {translations[language].language}
                                            <select name="language" value={formData.language} onChange={handleChange} style={{ textAlign: textAlignStyle }}>
                                          
                                                <option value="english"> {translations[language].english}</option>
                                                <option value="arabic"> {translations[language].arabic}</option>
                                                <option value="bulgarian"> {translations[language].bulgarian}</option>
                                                <option value="chinese"> {translations[language].chinese}</option>
                                                <option value="french"> {translations[language].french}</option>
                                                <option value="german"> {translations[language].german}</option>
                                                <option value="hindi"> {translations[language].hindi}</option>
                                                <option value="Korean"> {translations[language].korean}</option>
                                                <option value="portuguese"> {translations[language].portuguese}</option>
                                                <option value="russian"> {translations[language].russian}</option>
                                                <option value="spanish"> {translations[language].spanish}</option>
                                                <option value="dutch"> {translations[language].dutch}</option>
                                            </select>
                                        </label>


                            <label>
                                              {translations[language].specificpreferences}
                                            <textarea name="specificPreference" value={formData.specificPreference} onChange={handleChange} maxLength="1500" style={{ textAlign: textAlignStyle }}></textarea>
                            </label>


                                        <button type="submit">  {translations[language].createstory}</button>
                        </form>




                    ) : ( 

                        <div>
                                            <section class ="section">
                                         
                                            {showSuccessMessage && (
                                                    <div className="success-message">{translations[language].storycreatedsuccesfully}</div>
                                            )}
                                            {showFailMessage && (
                                                    <div className="success-message"> {translations[language].failedtocreatestory}</div>
                                                )}

                                                <div>
                                                    {translations[language].toviewyourlibrary}<a href="/library-form">{translations[language].here}</a>
                                                </div>
                                           
                                                </section>


                        </div>
                    )}

                        </>
                    ) : (<section class="section">
                            <p>{translations[language].unfortunatellyyouhaveconsumed}<a href="/managesubscribtion">{translations[language].managesubscribtion}</a> page.</p>
                   </section>
                            )}

                </div>
            ) : (
                    <div>
                        <section id="notloggedinadjusterforstoryform" class="section">
                            <div>
                                {translations[language].youarenotloggedin}<a href="/LoginPage">{translations[language].logingpage}</a>
                            </div>
                        </section>

                    </div>)
            }


            </div>
         


    );
}

export default StoryForm;

