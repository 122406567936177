import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './LibraryForm.css';
import { useLocation  } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import translations from './translations';

function GeneralLibrary() {
    const location = useLocation();
    const token = localStorage.getItem('token');
    const { isLoggedIn, genreselection } = location.state || {};

   // console.log("trying to check where the code reachs1 inside the general library fuinction", genreselection, "and is logged in status is ", isLoggedIn);
    const [stories, setStories] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [showTable, setShowTable] = useState(true); // State variable to control table visibility
    const [selectedStory, setSelectedStory] = useState([]);// State variable to store selected story details

    const [currentPairIndex, setCurrentPairIndex] = useState(0);

    const { language } = useContext(LanguageContext);

    const storiesPerPage = 15;
    //console.log("trying to check where the code reachs 2");
    useEffect(() => {
        // Function to fetch user stories from the backend
        const fetchUserStories = async () => {

           // console.log("trying to check where the code reachs3");
           // const userId = process.env.generalLibraryId;
            try {

             //   console.log("trying to check where the code reachs4");
                // Make API call to fetch user stories
                const response = await axios.get('/api/story/retrievestories', {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                    },
                    params: {
                        genreselection: genreselection,
                    }
                });
                // Update state with fetched stories
                setStories(response.data.rows);

              //  console.log("trying to check where the code reachs5");
            } catch (error) {

            //    console.log("trying to check where the code reachs6");
                console.error('Error fetching user stories:', error);
                // Handle error if any
            }
        };

        // Call the fetchUserStories function upon component mount
        fetchUserStories();
    }, []); // Empty dependency array to ensure the effect runs only once


    const indexOfLastStory = currentPage * storiesPerPage;
    const indexOfFirstStory = indexOfLastStory - storiesPerPage;
    const currentStories = stories.slice(indexOfFirstStory, indexOfLastStory);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // Placeholder function for viewing a story
    const viewStory = async (id) => {
        // Implement logic for viewing a story
     //   console.log("xxxxxxxxxxxxxx", id);

        try {
            // Fetch the selected story by ID


          //  console.log("we are trying to print the ID we got from teh table", id);
       
            const response = await axios.post('/api/story/retrievestorypages', {
                id: id
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Add the token to the Authorization header
                }
            });

            setSelectedStory(response.data.rows); // Assuming the API returns the story object
            setShowTable(false);

            // Handle displaying the story details (e.g., open a modal)
            //   console.log('Selected Story:', selectedStory);
        } catch (error) {
            console.error('Error fetching story details:', error);
        }
    };

    // Placeholder function for deleting a story
   // const deleteStory = (id) => {
        // Implement logic for deleting a story
   // };


    useEffect(() => {
        applyPageLogic();
    }, [selectedStory]);
    useEffect(() => {
      //  console.log("testing selected story object :", selectedStory);
    }, [selectedStory]);


    const applyPageLogic = () => {

      //  console.log("trying to check where apply page logicccc the code reachs1");
        const pages = document.getElementsByClassName('page');
     //   console.log("trying to check where apply page logicccc the code reachs1, page length", pages.length);
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (i % 2 === 0) {
                page.style.zIndex = pages.length - i;
            }
            page.page_number = i + 1;
            page.addEventListener('click', flipPage);
        }

      //  console.log("trying to check where apply page logicccc the code reachs2");
    };

    const flipPage = function () {
      //  console.log("trying to check where flipppppp the code reachs1", this.page_number);
        if (this.page_number % 2 === 0) {
         //   console.log("trying to check where flipppppp the code reachs2");
            this.classList.remove('flipped');
            this.previousElementSibling.classList.remove('flipped');
            setCurrentPairIndex(currentPairIndex + 2);
        } else {
       //     console.log("trying to check where flipppppp the code reachs3");
            this.classList.add('flipped');
            this.nextElementSibling.classList.add('flipped');
            setCurrentPairIndex(currentPairIndex - 2);
        }
    };
    function closeMessage() {
        var message = document.getElementById("messageForFlipPhones");
        message.style.display = "none";
    }


    return (


        <div id={showTable ? "" : "bookbackground"}>

            {isLoggedIn ? (
                <div className="library-form">

                    {showTable ? ( // Conditionally render the table or the story pages
                        <section class="section">
                        <React.Fragment>

                                <h2> {translations[language].generallibrary}</h2>
                            <table >
                                <thead>
                                    <tr>
                                            <th>{translations[language].title}</th>
                                            <th>{translations[language].creationdate}</th>
                                            <th>{translations[language].action}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentStories.map(story => {
                                        const formattedDate = new Date(story.created_at).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        });

                                        return (
                                            <tr key={story.id}>
                                                <td>{story.title}</td>
                                                <td>{formattedDate}</td>
                                                <td>
                                                    <button onClick={() => viewStory(story.id)}>{translations[language].view}</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="pagination">
                                {Array.from({ length: Math.ceil(stories.length / storiesPerPage) }).map((_, index) => (
                                    <button key={index + 1} onClick={() => paginate(index + 1)}>{index + 1}</button>
                                ))}
                            </div>


                            </React.Fragment>
                            </section>
                    ) : (<div >
                            <div id="messageForFlipPhones" >
                                <p>{translations[language].flipdeviceforbetterview}</p>
                                <button class="close-button" onClick={() => closeMessage()}>X</button>
                            </div>
                            <div id="libraryforminsidediv">
                        <div className="book">
                                    <div className="page frontpage-background">
                                        <div id="booktitlestyle">  {selectedStory[0].title}
                                        </div>
                                    </div>
                            {selectedStory.map((page, index) => (
                                <React.Fragment key={page.id}>
                                    {/* Render text page on the left */}
                                    <div className="page">
                                        <div className="page-content">{page.page_text}</div>
                                    </div>

                                    {/* Render image page on the right */}
                                    <div className="page">
                                        <div className="page-content">
                                            <img src={page.image_url} alt={`Page ${index + 1}`} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}




                                <div className="page backpage-background"></div>

                                </div>
                            </div>
                            <div id="librarynavigation-buttons">
                                <button onClick={() => setShowTable(true)}> {translations[language].gobacktolibrary}</button>
                        </div>
                    </div>
                    )


                    }




                </div>
            ) : (
                <div>
                        <section id="notloggedinadjusterforlibraryform"  class="section">
                        <div>
                                {translations[language].youarecurrentlynotloggedin}    <a href="/LoginPage">{translations[language].logingpage}</a>
                        </div>
                    </section>

                </div>
            )

            }
        </div>



    );
}

export default GeneralLibrary;
