import React from 'react';

import './SlideShow.css';

function SlideShow() {

    return (
           <div>

            <ul class="slideshow">
                <li><span></span></li>
                <li><span>2</span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
                                            </div>
                                            );
}

export default SlideShow;
