import React, { useContext } from 'react';

//import { Link } from 'react-router-dom';
import './NewHeader.css';
import { LanguageContext } from './LanguageContext';
import translations from './translations';


function NewHeader({ isLoggedIn, userTier, onLogout }) {

  //  console.log("we are inside the new header, showing the loggedin astatus", isLoggedIn);
   // console.log("we are inside the new header, showing the user tierrrrrrrrrr", userTier);

    const { language } = useContext(LanguageContext);
   /*  {userTier === "Paid" && (

                            )}
                            {userTier === "Free" && (
                                <li><a href="/PackageCheckout">{translations[language].upgradetopremium}</a></li>
                            )}*/

    return (
        <header >
            <div class="container">
                <div id="logoHeaderMenu">
                  
            </div>
          <div id="menubarnexttologo">

                <ul class="menu">
                    {isLoggedIn ? (



                    <>
                            <li> <a href="/home">{translations[language].home}</a></li>
                            <li> <a href="/Dashboard">{translations[language].dashboard}</a></li>
                            <li><a href="/managesubscribtion"> {translations[language].managesubscribtion}</a></li>
                          
                            <li>   <button onClick={() => {
                                onLogout(); // Call the onLogout function
                                window.location.href = "/home"; // Redirect the user to the home page
                            }}>{translations[language].logout}</button>



                            </li>
                        <li class="slider"></li>
                    </>





                    ) : (
                        <>
                               
    
                                <li><a href="/home">{translations[language].home}</a></li>
                                <li><a href="/PricingPage">{translations[language].pricing}</a></li>

                                <li><a href="/LoginPage">{translations[language].loginpage}</a></li>
                                <li><a href="/SignUpPage">{translations[language].signup}</a></li>





                              
                            <li class="slider"></li>
                    </>
                    )}
                </ul>

                </div>

              </div>
         
        </header>
    );
}

export default NewHeader;
