import React, { useContext } from 'react';

import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTiktok , faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import specific icons
import { LanguageContext } from './LanguageContext';

import translations from './translations';

function Footer() {
    const { language, setLanguage } = useContext(LanguageContext);

   


    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        setLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage); // Update localStorage
    };
   
   
    return (
		
        <main id="mainfooter">

     
            <footer>
                <div id="headhalffooter">


                    <div id="halffooterone">
                <div>
                    <label htmlFor="language-select" id="language-select-color">{translations[language].chooselanguage}  </label>
                            <select id="languagebutton" onChange={handleLanguageChange} value={language}>
                        <option value="en">{translations[language].english} </option>
                        <option value="ar">{translations[language].arabic} </option>
                        <option value="bg">{translations[language].bulgarian} </option>
                        <option value="zhcn">{translations[language].chinese} </option>
                        <option value="fr">{translations[language].french} </option>
                        <option value="de">{translations[language].german}</option>
                        <option value="hi">{translations[language].hindi}</option>
                        <option value="ko">{translations[language].korean}</option>
                        <option value="pt">{translations[language].portuguese}</option>
                        <option value="ru">{translations[language].russian}</option>
                        <option value="es">{translations[language].spanish}</option>
                        <option value="nl">{translations[language].dutch}</option>


                    </select>


                </div>

              
                
                <div class="footer-text"> {translations[language].forsupportemailusat}  support@GwowAI.com . </div>

                        <div id="termsandcresize">

                    <a id="footertext" href="/terms-and-conditions">Terms and Conditions</a>
                </div>
              


                    </div>



                    <div id="halffootertwo">
                        <div>

                          <ul>
                        <li>
                                    <a className="facebook" href="https://www.facebook.com/gwowaiworld" target="_blank" rel="noopener noreferrer">
                                        <span style={{ background: '#3b5998' }} ></span>
                                <span></span>
                                        <span style={{ background: '#3b5998' }}></span>
                                        <span   ></span>
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                        </li>
                        <li>
                                    <a className="tiktok" href="https://www.tiktok.com/@gwowai" target="_blank" rel="noopener noreferrer">
                                        <span style={{ background: 'black' }}></span>
                                <span></span>
                                        <span style={{ background: 'black' }}></span>
                                        <span ></span>
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </li>
                        <li>
                                    <a className="instagram" href="https://www.instagram.com/gwow_ai" target="_blank" rel="noopener noreferrer">
                                        <span style={{ background: '#c32aa3' }}></span>
                                <span></span>
                                        <span style={{ background: '#c32aa3' }}></span>
                                <span></span>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>

                    </ul>
                        </div>

                     </div>

                </div>

				</footer>
			</main>

		

		

    );
}

export default Footer;
