import React from 'react';

import './SlideShowLeft.css';

function SlideShowLeft() {

    return (
        <div>

            <ul className="slideshowleft">
                <li><span></span></li>
                <li><span>2</span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
        </div>
    );
}

export default SlideShowLeft;
