import React, { useState, useEffect , useContext } from 'react';
import axios from 'axios'; // Import axios for making API calls
import { LanguageProvider, LanguageContext } from './LanguageContext';
import translations from './translations';
//import React from 'react';
import StoryForm from './StoryForm';
import LibraryForm from './LibraryForm';
import GeneralLibrary from './GeneralLibrary';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate, useLocation  } from 'react-router-dom';

import TermsAndConditions from './TermsAndConditions';
import LearnMore from './LearnMore';
    import NewHeader from './NewHeader';
import Footer from './Footer';
import SlideShow from './SlideShow';
import SlideShowLeft from './SlideShowLeft';

//import * as jwt_decode from 'jwt-decode';

//import { default as jwt_decode } from 'jwt-decode';
import jwt_decode from 'jwt-decode';

import { useParams, Link  } from 'react-router-dom';
import './App.css';

function App() {
    // Set default authorization header
    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token'); // or however you are storing it
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

   // console.log("Is jwt_decode a function?", typeof jwt_decode === 'function');
    //const navigate = useNavigate(); // Move useNavigate hook call here

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
    const [userTier, setUserTier] = useState(null); // Initialize userTier state
    const [userEmail, setUserEmail] = useState(null); // Initialize userEmail state
    const [userCurrentPackage, setUserCurrentPackage] = useState(null); // Initialize userEmail state
    const [userBalance, setUserBalance] = useState(localStorage.getItem('balance') || null);
    const [autoRenew, setAutoRenew] = useState(null); // Initialize userEmail state
    
    const [extId, setExtId] = useState(null); // Initialize userEmail state
    
    const [endDate, setEndDate] = useState(null); // Initialize userEmail state
    
  //  const [availableBalance, setAvailableBalance] = useState(null); // Initialize userEmail state




   


    const handleLogin = () => {

    //    console.log("we are inside the handlelogin changing the sattus of is loggedin", isLoggedIn);
        // Implement login functionality
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
     //   console.log("we are inside the handlelogin changing the sattus of is loggedin", isLoggedIn);
        
        const balance = userBalance;
        localStorage.setItem('balance', balance);
    //    console.log("we are inside the handlelogin changing the sattus of is loggedin, the balance is", balance);
    };


    useEffect(() => {
        if (userBalance !== null) {
            localStorage.setItem('balance', userBalance);
          //  console.log("Balance updated in local storage:", userBalance);
        }
    }, [userBalance]);

    useEffect(() => {
        async function fetchUserTier() {
          

            try {

            //    console.log("we are inside the useeffect for fetchusertier");
                const token = localStorage.getItem('token');
                const response = await axios.post('/api/story/verifysessiontoken', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const tier = response.data.tier;
                const userEmail = response.data.userEmail;
               // const userId = response.data.userId;
                const userCurrentPackage = response.data.userCurrentPackage;
                const fetchedBalance = response.data.balance;
               
                const autoRenew = response.data.autoRenew;
                const endDate = response.data.endDate;
            //    console.log("end date in the techusertier", endDate);
                const extId = response.data.extId;
               // console.log('User tierrrrrrrrrrrrrrrrrrrrrrrr entaa:', userEmail, userId, tier, response.data.balance);
             //   const balance = response.data.balance;

              //  console.log('User tierrrrrrrrrrrrrrrrrrrrrrrr entaa:', userEmail, userId, tier, balance);
                setUserTier(tier); 
                setUserEmail(userEmail);
                setUserCurrentPackage(userCurrentPackage);
                setAutoRenew(autoRenew);
                setEndDate(endDate);
                
                setExtId(extId);
              //  localStorage.setItem('userId', userId);
                // localStorage.setItem('balance', balance);
                //setUserBalance(response.data.balance);
                let localStorageBalance = localStorage.getItem('balance');

                if (localStorageBalance !== null && localStorageBalance !== "null") {
                    localStorageBalance = Number(localStorageBalance);
                } else {
                    localStorageBalance = null; 
                }
            //    console.log("Converted localStorageBalance:", localStorageBalance);
             //   console.log("fetchedBalance before condition check:", fetchedBalance);
                if (localStorageBalance !== null && fetchedBalance !== localStorageBalance) {
                    // If the balance in local storage is different from the fetched balance, use the local storage balance
                    setUserBalance(localStorageBalance);
             //       console.log("setting the userbalance after checking if local storage is not null and if the fetched variable is different. first fetched second local", fetchedBalance, localStorageBalance);
                } else { 
                    // Otherwise, use the fetched balance
                    setUserBalance(fetchedBalance);
                 //   console.log("otherwise use the fetched for first timeeee. first fetched second local", fetchedBalance, localStorageBalance);

                }
            } catch (error) {
                console.error('Verification error:', error);
                // Handle error here, e.g., display error message to the user
            }
        }

        if (isLoggedIn) {
            fetchUserTier(); // Call the fetchUserTier function when the component mounts
        }
    }, [isLoggedIn]);

  



    const handleLogout = () => {
  
        //const navigate = useNavigate();
        // Remove the JWT from local storage
        localStorage.removeItem('token');
        // Update the isLoggedIn state to false
        setIsLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('balance');
        // Redirect the user to the login page
       // navigate('/home');
    };


    // Periodic token expiration check
    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = localStorage.getItem('token');
      //      console.log("insie the useeffect to check the token "+token);
            if (token) {
                try {
              //      console.log("insie the useeffect to check the token 1");
                 //   const decodedToken = jwt_decode(token);
                    let decodedToken;
                    try {
                        decodedToken = jwt_decode(token);
               //         console.log("Decoded Token:", decodedToken);
                    } catch (error) {
                        console.error("Token decoding failed:", error.message);
                    }


             //       console.log("Decoded token:", decodedToken); // Log the decoded token for debugging
                    // Check if the token is expired
                    if (decodedToken.exp * 1000 < Date.now()) {
                  //      console.log("insie the useeffect to check the token 2 ");
                        localStorage.removeItem('token'); // Clear token
                        localStorage.removeItem('isLoggedIn'); // Clear login state
                        setIsLoggedIn(false); // Update state
                    }
                } catch (error) {
                  //  console.log("insie the useeffect to check the token 3 ");
                    console.error("Token decoding failed:", error);
                    localStorage.removeItem('token'); // Clear token on error
                    localStorage.removeItem('isLoggedIn');
                    setIsLoggedIn(false);
                }
            }
        };

        // Check token immediately when component mounts
        checkTokenExpiration();

        // Set up an interval to check the token every minute (60000 ms)
        const intervalId = setInterval(checkTokenExpiration, 3720000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

  



    return (

        <LanguageProvider>
        <Router>
            <div id = "AppContainer">
                <NewHeader isLoggedIn={isLoggedIn} userTier={userTier} onLogout={handleLogout} />

                <Routes>
                    <Route path="/home" element={<LandingPage />} />
                    <Route path="/" element={<LandingPage />} />
                  
                    <Route path="/LoginPage" element={<LoginPage onLogin={handleLogin}  />} />

                    <Route path="/SignUpPage" element={<SignUpPageFree />} />
                    <Route path="/SignUpPagePaid" element={<SignUpPagePaid />} />
                    <Route path="/PricingPage" element={<PricingPage />} />
                        <Route path="/story-form" element={<StoryForm isLoggedIn={isLoggedIn} />} />
                    <Route path="/library-form" element={<LibraryForm isLoggedIn={isLoggedIn} />} />
                    <Route path="/general-library" element={<GeneralLibrary />} />
                    <Route path="/general-library-options" element={<GeneralLibraryOptions isLoggedIn={isLoggedIn} />} />

                    <Route path="/Dashboard" element={<Dashboard isLoggedIn={isLoggedIn} userTier={userTier} />} />
                    <Route path="/verify-email/:token" element={<VerifyEmail />} />
                  
                    <Route path="/verify-pemail/:token" element={<VerifyPEmail />} />

                    <Route path="/initiateresetpassowrd" element={<InitiateResetPassowrd />} />
                    <Route path="/resetpassword/:token" element={<ResetPassword />} />
                        <Route path="/packagecheckout" element={<PackageCheckout isLoggedIn={isLoggedIn} userEmail={userEmail} />} />
                        <Route path="/managesubscribtion" element={<ManageSubscribtion isLoggedIn={isLoggedIn} userTier={userTier} userEmail={userEmail} userCurrentPackage={userCurrentPackage} autoRewnew={autoRenew} endDate={endDate} extId={extId}  setIsLoggedIn={setIsLoggedIn} />} />
                        <Route path="/orderComplete" element={<OrderComplete isLoggedIn={isLoggedIn} />} />
                        <Route path="/paymentIssue" element={<PaymentIssue isLoggedIn={isLoggedIn} />} />
                        <Route path="/underReview" element={<UnderReview isLoggedIn={isLoggedIn} />} />
                        <Route path="/pendingPayment" element={<PendingPayment isLoggedIn={isLoggedIn} />} />
                        <Route path="/blockedAccount" element={<BlockedAccount  />} />


                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/learnmore" element={<LearnMore/>} />
                        
                </Routes>

                    <Footer />
             
            </div>
         
        </Router>
        </LanguageProvider>

    );
}
/*function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return null;
    }
}*/

function GeneralLibraryOptions({ isLoggedIn }) {

    //adding a comment to checkgithub
    const navigate = useNavigate();

    const handleGenreSelection = (genre) => {
      //  console.log("we are calling the general lbrary function with this genre", genre, "and this state of logged in ", isLoggedIn);

        navigate('/general-library', { state: { isLoggedIn, genreselection: genre } });

    };
    const { language } = useContext(LanguageContext);



    return (
        <section   className="section">
            <div>
                {isLoggedIn ? (
                    <div>
                        <h1 id="gnerallibraryoptionsheader">{translations[language].chooseacategory}</h1>

                        <div class="containerbookselection">

                            <div onClick={() => handleGenreSelection('genre1')}>
                      
                                <h2 id='contentbookselectiontextipad'>{translations[language].mythicalcreatures}</h2>

                                <div class="contentbookselection">

                                    <h2 id='contentbookselectiontext'>{translations[language].mythicalcreatures}</h2>

                                    </div>
                            </div>
                            <div onClick={() => handleGenreSelection('genre2')}>
                                <h2 id='contentbookselectiontextipad'>{translations[language].momentstogether}</h2>

                                <div class="contentbookselection">
                                    <h2 id='contentbookselectiontext'>{translations[language].momentstogether}</h2>
                                </div>
                            </div>
                          
                            <div onClick={() => handleGenreSelection('genre3')}>
                                <h2 id='contentbookselectiontextipad'>{translations[language].toys}</h2>

                                <div class="contentbookselection">
                                    <h2 id='contentbookselectiontext'>{translations[language].toys}</h2>
                                </div>
                            </div>
                            

                        </div>


                        <div class="secondcontainerbookselection">
                            <div onClick={() => handleGenreSelection('genre4')}>
                                <h2 id="contentbookselectiontextipad">{translations[language].animals}</h2>

                                <div class="secondcontentbookselection">
                                    <h2 id="contentbookselectiontext">{translations[language].animals}</h2>
                                  
                                </div>
                            </div>
                            <div onClick={() => handleGenreSelection('genre5')}>
                                <h2 id="contentbookselectiontextipad" style={{ fontSize: '38px' }}>{translations[language].superheroes}</h2>


                                <div class="secondcontentbookselection">
                                    <h2 id="contentbookselectiontext" style={{ fontSize: '38px' }}>{translations[language].superheroes}</h2>
                                  
                                </div>
                            </div>
                            <div onClick={() => handleGenreSelection('genre6')}>
                                <h2 id="contentbookselectiontextipad">{translations[language].VariousTales}</h2>

                                <div class="secondcontentbookselection">
                                    <h2 id="contentbookselectiontext">{translations[language].VariousTales}</h2>
                                   
                                </div>
                            </div>
                    
                           

                        </div>




                    </div>
                ) : (
                        <div id="generallibraryoptionnotloggedinforsmallscreens" >
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                            </div>
                            
                    </div>
                )}
            </div>
        </section>
    );
}


function InitiateResetPassowrd() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { language } = useContext(LanguageContext);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/story/initiateresetpassword', { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error initiating password reset. Please try again later.');
        }


    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value.toLowerCase());
    };

    return (
        <section id="resetformid" className="section">
            {!message ? (
                <form id="loginsectionform" onSubmit={handleSubmit}>
                    <h3>{translations[language].resetpassword}</h3>
                    <div style={{ paddingTop: '20px' }}>
                        <div className="loginform">
                            <div className="subtitle">{translations[language].enteremailtoresetpassowrd}</div>
                            <div className="input-container ic2">
                                <input
                                    id="email"
                                    className="input"
                                    type="email"
                                    placeholder=" "
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <label htmlFor="email" className="placeholder">{translations[language].email}</label>
                            </div>
                            <button type="submit" className="submit">{translations[language].submit}</button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="success-messagelogin">{message}</div>
            )}
        </section>
    );
}

function ResetPassword() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [resetSuccessful, setResetSuccessful] = useState(false);
    const { language } = useContext(LanguageContext);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match. Please try again.');
            return;
        }
        try {
            const response = await axios.post(`/api/story/resetpassword/${token}`, { password });
            setMessage(response.data.message);
            if (response.status === 200) {
                setResetSuccessful(true);
            }
        } catch (error) {
            setMessage('Error resetting password. Please try again later.');
        }
    };


    return (
        <section id="section2" className="section">
            {!resetSuccessful ? (
                <form onSubmit={handleSubmit}>
                    <h3>{translations[language].resetpassword}</h3>
                    <div style={{ paddingTop: '20px' }}>
                        <div id="resetformidloginadjuster"  className="loginform">
                            <div className="subtitle">{translations[language].enteryournewpassword}</div>
                            <div className="input-container ic2">
                                <input
                                    id="password"
                                    className="input"
                                    type="password"
                                    placeholder=" "
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <label htmlFor="password" className="placeholder">{translations[language].newpassword}</label>
                            </div>
                            <div className="input-container ic2">
                                <input
                                    id="confirmPassword"
                                    className="input"
                                    type="password"
                                    placeholder=" "
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <label htmlFor="confirmPassword" className="placeholder">{translations[language].confirmnewpassword}</label>
                            </div>
                            <button type="submit" className="submit">{translations[language].submit}</button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="success-messagelogin">
                    {message}
                        {message === 'Password has been reset successfully' && <Link to="/LoginPage">{translations[language].gotologin}</Link>}
                </div>
            )}
            {!resetSuccessful && message && <div className="error-message">{message}</div>}
        </section>
    );
}


function Dashboard({ isLoggedIn, userTier }) {

    const { language } = useContext(LanguageContext);
  

    return (
        <section className="section">
            <div>
                {isLoggedIn ? (
                    <div>
                        {userTier === 'Free' && (
                            <div id="bodycontainer">
                                <div className="Dashboardcontainer">
                                    <div className="btn">
                                        <a href="/general-library-options">{translations[language].generallibrary}</a>
                                    </div>
                                </div>

                                <div className="Dashboardcontainer">
                                    <div className="btn">
                                        <a href="/managesubscribtion">{translations[language].completeregistration}</a>
                                    </div>
                                </div>



                            </div>
                        )}
                        {userTier === 'Paid' && (
                            <div id="bodycontainer">
                                <div className="Dashboardcontainer">
                                    <div className="btn">
                                        <a href="/library-form">{translations[language].yourlibrary}</a>
                                    </div>
                                    <div className="btn">
                                        <a href="/story-form">{translations[language].createnewbook}</a>
                                    </div>
                                    <div className="btn">
                                        <a href="/general-library-options">{translations[language].generallibrary}</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                        <div id="generallibraryoptionnotloggedinforsmallscreens" >
                            {translations[language].youarecurrentlynotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>
                    </div>
                )}
            </div>
        </section>
    );
}

function PackageCheckout({ isLoggedIn, userEmail}) {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const isLoggedInFromVerifying = queryParams.get('isLoggedInFromVerifying');
    const emailfromverification = localStorage.getItem('email');

    const [selectedPackage, setSelectedPackage] = useState('');
    const [paymentStarted, setPaymentStarted] = useState(false);
    //console.log("now we are inside the packagecheckout page displaying the isloggedin value passed", isLoggedInFromVerifying);
   // console.log("now we are inside the packagecheckout page displaying the isloggedin value passed,emaillll", emailfromverification);
   // console.log("now we are inside the packagecheckout pemaillll from signin zay el bane2dmen", userEmail);

    const { language } = useContext(LanguageContext);

    const handlePackageChange = (event) => {
        setSelectedPackage(event.target.value);
    };

    const handleBuyNowClick = () => {
        if (selectedPackage) {
            window.open(selectedPackage, '_blank');
            setPaymentStarted(true);
        } else {
            alert('Please select a package before proceeding.');
        }
    };

    return (

        <section className="section">
            <div>
                {isLoggedIn || isLoggedInFromVerifying ? (
                    <div>
                        {!paymentStarted ? (
                            <>

                                <p id="selectpackagetext">{translations[language].nowpleaseconfirmwhichpackage} {userEmail || emailfromverification}. {translations[language].onceyouclickonthebuybutton}</p>
                                <label htmlFor="packageSelect">{translations[language].chooseapackage}</label>
                        <select id="packageSelect" value={selectedPackage} onChange={handlePackageChange}>
                                    <option value="" disabled>{translations[language].selectyourpackage}</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/gwowaicheckout/quest">Quest-{translations[language].monthly}- $4.99</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/quest-yearly">Quest/{translations[language].yearly} - One Month Free! $54.89</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/odyssey">Odyssey-{translations[language].monthly} - $8.99</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/odyssey-yearly">Odyssey/{translations[language].yearly} - One Month Free! $98.89</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/enchanted">Enchanted-{translations[language].monthly} - $14.99</option>
                                    <option value="https://rewayaaicheckout.test.onfastspring.com/enchanted-yearly">Enchanted/{translations[language].yearly} - One Month Free! $164.89</option>
                        </select>
                        <div class="buttons-container">
                                    <button class="button-arounder" onClick={handleBuyNowClick}>{translations[language].buynow}</button>
                                </div>
                            </>
                        ) : (
                                <p id="selectpackagetext">{translations[language].thankyouforchoosingpackage} <a href="/Dashboard">here</a>.</p>
                        )}
                    </div>

        
                ) : (
                        <section id="notloggedinadjusterforsectionforappjs" class="section">
                        <div>
                            {translations[language].youarecurrentlynotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>
                        </div>
                            </section>
                )}
</div></section>

                );
}


function ManageSubscribtion({ isLoggedIn, userTier, userEmail, userCurrentPackage, autoRenew, extId, endDate, setIsLoggedIn}) {
    const { language } = useContext(LanguageContext);
    let availableBalance = localStorage.getItem('balance');
    availableBalance = availableBalance === null ? 0 : availableBalance;
   // console.log("aavilable balance is " + availableBalance);
   // console.log("date sent " + endDate);
    let formattedEndDate;
   // console.log("the current end date in the datanase is  ", endDate );
    // Convert endDate into readable format (e.g., "Monday, October 26, 2024")
    if (endDate) {
        formattedEndDate = new Date(endDate).toLocaleDateString('en-US', {
        weekday: 'long',  // Display day name (e.g., Monday)
        year: 'numeric',  // Display year (e.g., 2024)
        month: 'long',    // Display month name (e.g., October)
        day: 'numeric'    // Display day (e.g., 26)
    });
    }
   // console.log("formatted end date: ", formattedEndDate);
    
        // Use state to track whether the user is viewing monthly or annual packages
        const [isMonthly, setIsMonthly] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false); // New state for confirmation dialog
    const [showRenewConfirmation, setShowRenewConfirmation] = useState(false);    
        function toggleBilling() {
            setIsMonthly(!isMonthly);
    }



    
    const handleSubscriptionClick = (productId) => {

     //   console.log("Clicked subscription button for product ID:", productId);

        // Construct the payment URL directly on the frontend
      //  const secretKey = '8GXjMEoWKq';  // You can retrieve this from your .env file
      //  const useTestMode = '%27true%27';   // Set to false for production
        const pageTemplateId = '18722';  // Page template ID

        // Construct the payment URL
      //  const paymentURL = `https://store.payproglobal.com/checkout?products[1][id]=${productId}&use-test-mode=${useTestMode}&secret-key=${secretKey}&page-template=${pageTemplateId}`;
        const paymentURL = `https://store.payproglobal.com/checkout?products[1][id]=${productId}&page-template=${pageTemplateId}&billing-email=${userEmail}`;

   //    console.log("Constructed payment URL:", paymentURL);
         
        // Redirect to the constructed URL
       window.location.href = paymentURL;
    };

   


    // Show confirmation popup
    const confirmCancellation = () => {
        setShowConfirmation(true); // Show the confirmation pop-up
    };

    // Handle user decision in confirmation
    const handleConfirmationResponse = (confirm, userEmail) => {
        if (confirm) {
            handleCancelSubscription(userEmail);
        }
        setShowConfirmation(false); // Hide the confirmation
    };

    const confirmRenewal = () => {
        setShowRenewConfirmation(true); // Show renew confirmation pop-up
    };

    // Handle user decision in confirmation (renew)
    const handleRenewConfirmationResponse = (confirm) => {
        if (confirm) {
            handleRenewSubscription();  // Proceed with renew if confirmed
        }
        setShowRenewConfirmation(false); // Hide the confirmation
    };

    // Handle cancel subscription button click

    const handleCancelSubscription = async (userEmail) => {
        try {

            const token = localStorage.getItem('token');
            // Send request to cancel the subscription

         /*   const response = await axios.post('/api/story/terminateButton', {
                email: userEmail,  // Pass userId in the request body
            });
            */
            const response = await axios.post('/api/story/terminateButton', {}, {
                headers: {
                    Authorization: `Bearer ${token}` // Include token in headers for authentication
                }
            });


            if (response.status === 200) {
                // Notify the user of successful cancellation and the upcoming logout
                alert('Subscription canceled successfully. You will be logged out in a few seconds.');

                // Wait for 5-7 seconds (randomly choosing between 5 and 7 seconds)
                const delay = Math.random() * (7000 - 5000) + 5000;
                setTimeout(() => {
                    // Perform the logout operations
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                    localStorage.removeItem('isLoggedIn');
                    localStorage.removeItem('balance');

                    // Optionally redirect or refresh the UI
                    window.location.href = '/LoginPage'; // Redirect to login or home page after logout
                }, delay);

            } else {
                alert('Failed to cancel subscription.');
            }
        } catch (error) {
            console.error("Error during subscription cancellation:", error);
            alert('Error occurred while canceling the subscription.');
        }
    };

    // Handle manual renewal button click
    const handleRenewSubscription = () => {
      //  const secretKey = '8GXjMEoWKq';  // Retrieve this from your .env file
      //  const useTestMode = '%27true%27';  // Set to false for production
        const pageTemplateId = '18722';  // Page template ID

        // Construct the renew subscription URL with necessary parameters
       // const renewURL = `https://store.payproglobal.com/checkout?subscr-id=${extId}&use-test-mode=${useTestMode}&secret-key=${secretKey}&page-template=${pageTemplateId}`;
        const renewURL = `https://store.payproglobal.com/checkout?subscr-id=${extId}&page-template=${pageTemplateId}&billing-email=${userEmail}`;

        window.location.href = renewURL; // Redirect to renew subscription
    };

    return (


        <section className="section custom-color-for-managesub">
                {isLoggedIn ? (
                   
                    <div>
                        <div  id="subscriptiondetails">
                        {/* Part 1: Current Subscription Info */}

                        <p id="submanage__info_1">
                          {translations[language].currentsubscriptiondetails}

                        </p>

                        <p id="submanage__info_2">
                            {translations[language].packagename}
                            <strong id="submanage__info_2_2" >{userCurrentPackage}</strong>
                              
                        </p>
                        <p id="submanage__info_2">
                            {translations[language].enddate}
                            <strong id="submanage__info_2_2" >{formattedEndDate}</strong>

                        </p>
                        <p id="submanage__info_2">
                            {translations[language].remainingbalance}
                            <strong id="submanage__info_2_2" >{availableBalance}</strong>

                        </p>

                        <p id="submanage__info_3">
                            {translations[language].emailaddress}
                            <strong id="submanage__info_3_3" >{userEmail}</strong>
                        </p>

                        {userCurrentPackage !== "Free" && userCurrentPackage !== null && (
                        <div id="cancelrenewoptions">
                        {/* Conditionally show the "Renew Subscription" button */}
                        {!autoRenew && (
                            <button
                                id="renewSubButton"
                                    onClick={confirmRenewal}
                            >
                                    {translations[language].renewsubscription}
                            </button>
                                )}

                                {/* Confirmation Pop-Up for Renew */}
                            {showRenewConfirmation && (
                                <div id="confirmation-overlay">
                                    <div id="confirmation-popup">
                                        <p id="confirmation-popupfont">     {translations[language].confirrenewsubscriptiondate} </p>
                                        <div id="popup-buttons">
                                            <button onClick={() => handleRenewConfirmationResponse(true)}>{translations[language].yes}</button>
                                            <button onClick={() => handleRenewConfirmationResponse(false)}>{translations[language].no}</button>
                                        </div>
                                    </div>
                                </div>
                            )}



             

                        {/* Cancel Subscription Button */}
                        <button
                            id="cancelSubButton"
                            onClick={confirmCancellation}
                        >
                                {translations[language].cancelsubscription}
                        </button>

                            </div>


                        )}

                        {/* Confirmation Pop-Up */}
                        {showConfirmation && (
                            <div id="confirmation-overlay">
                                <div id="confirmation-popup">
                                    <p id="confirmation-popupfont">{translations[language].confirmsubcancelation} </p>
                                    <div id="popup-buttons">
                                        <button onClick={() => handleConfirmationResponse(true, userEmail)}>{translations[language].yes}</button>
                                        <button onClick={() => handleConfirmationResponse(false)}>{translations[language].no}</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        </div>

                        <div id="subscriptionupgrade">
                            <p id="submanage__upgrade-text">
                            {translations[language].toupgradeyourpackage}    
                            </p>
                        
                      

                        {/* Part 2: Toggle Button for Monthly/Annually */}

                        <div id="submanage__toggle-container_header">
                            <div id="submanage__toggle-container">
                                <h id={isMonthly ? 'subscriptionmonthlyleftclicked' :  'subscriptionmonthlyleft'}>
                                    {' Monthly'}
                                </h>
                            <div className="wrappersubpage">
                                  
                                    <input type="checkbox" id="toggleButton" onClick={toggleBilling} />
                                    <label htmlFor="toggleButton"></label>
                                   
                            </div>
                                <h id={isMonthly ? 'subscriptionmonthlyleft' : 'subscriptionmonthlyleftclicked'}>
                                    {' Annually'}
                                </h>
                        </div>

                            </div>
                        {/* Part 3: Available Packages */}




                        {isMonthly && (




                        <div  id="monthlyPackages" >

                      

                                <div class="wrapper-sub">

                              
                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Quest</h4>
                                    </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                            <h1>$7.99</h1>
                                        </div>
                                        <ul>
                                          
                                            <li>{translations[language].questbook}</li>


                                        </ul>
                                         


                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95622')}
                                                >
                                                    Buy
                                                </button>
                                            </div>


                                    </div>
                                </div>
                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Odyssey</h4>
                                    </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                            <h1>$14.99</h1>
                                        </div>
                                        <ul>
                                         
                                            <li>{translations[language].odysseybook}</li>


                                        </ul>
                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95624')}
                                                >
                                                  Buy
                                                </button>
                                            </div>
                                    </div>
                                </div>
                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Enchanted</h4>
                                    </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                            <h1>$26.99</h1>
                                        </div>
                                        <ul>
                                    
                                            <li>{translations[language].enchantedbook}</li>
                                        </ul>
                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95648')}
                                                >
                                                    Buy
                                                </button>
                                            </div>
                                    </div>
                                </div>

                            </div>
                      



                        </div>


                        )}

                        {!isMonthly && (
                            <div id="annualPackages">



                                <div class="wrapper-sub">


                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Quest</h4>
                                        </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                                <h1>$87.99</h1>
                                            </div>
                                            <ul>
                                            
                                                <li>{translations[language].questbookannually}</li>


                                            </ul>
                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95649')}
                                                >
                                                    Buy
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Odyssey</h4>
                                        </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                                <h1>$164.99</h1>
                                            </div>
                                            <ul>
                                              
                                                <li>{translations[language].odysseybookannually}</li>


                                            </ul>
                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95650')}
                                                >
                                                    Buy
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pricing-table-sub gprice-single">
                                        <div class="head-sub">
                                            <h4 class="title-sub">Enchanted</h4>
                                        </div>
                                        <div class="content-sub">
                                            <div class="price-sub">
                                                <h1>$296.99</h1>
                                            </div>
                                            <ul>
                                            
                                                <li>{translations[language].enchantedbookannually}</li>
                                            </ul>
                                            <div className="sign-up-sub">
                                                <button
                                                    className="btn-sub bordered-sub radius"
                                                    onClick={() => handleSubscriptionClick('95651')}
                                                >
                                                    Buy
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        



                            </div>


                        )}



                        <p id="submanage__upgrade-text">
                            {translations[language].ifyoudecidetoupgrade}
                        </p>



                    </div>

                    </div>

            ) : (
             

                    <div id="generallibraryoptionnotloggedinforsmallscreens" className= "generallibraryoptionnotloggedin">
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                        </div>
                    </div>


            )}
        </section>
    );
}



function OrderComplete({ isLoggedIn }) {

    const { language } = useContext(LanguageContext);
    return (

        <section className="section custom-margin ">
            {isLoggedIn ? (

                    <div>
                    {translations[language].subscriptionsuccessful}


                    </div>
            ) : (
                    <section id="notloggedinadjusterforsectionforappjs" class="section">
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                        </div>
                  </section>
            )}
             
           </section>

    );
}

function PaymentIssue({ isLoggedIn }) {

    const { language } = useContext(LanguageContext);
    return (

        <section className="section custom-margin ">

            {isLoggedIn ? (

                <div>
                    {translations[language].paymentissue}


                </div>
            ) : (
                    <div id="generallibraryoptionnotloggedinforsmallscreens" className="generallibraryoptionnotloggedin">
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                        </div>
                    </div>
            )}

        </section>

    );
}


function UnderReview({ isLoggedIn }) {

    const { language } = useContext(LanguageContext);
    return (

        <section className="section custom-margin ">

            {isLoggedIn ? (

                <div>
                    {translations[language].underreview}


                </div>
            ) : (
                    <div id="generallibraryoptionnotloggedinforsmallscreens" className="generallibraryoptionnotloggedin">
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                        </div>
                    </div>
            )}

        </section>

    );
}


function PendingPayment({ isLoggedIn }) {

    const { language } = useContext(LanguageContext);
    return (

        <section className="section custom-margin ">

            {isLoggedIn ? (

                <div>
                    {translations[language].yourorderiscompleted}


                </div>
            ) : (
                    <div id="generallibraryoptionnotloggedinforsmallscreens" className="generallibraryoptionnotloggedin">
                        <div >
                            {translations[language].youarenotloggedin}{' '}
                            <a href="/LoginPage">{translations[language].logingpage}</a>

                        </div>
                    </div>
            )}

        </section>

    );
}

function BlockedAccount(isLoggedIn) {

    const { language } = useContext(LanguageContext);
    return (

        <section id="notloggedinadjusterforsectionforappjs"  className="section custom-margin ">

          
                <div>
                   
                {translations[language].somethingwrongcontactsupport}

                </div>
            

        </section>

    );
}


function VerifyEmail() {
   
    const { language } = useContext(LanguageContext);
    const [verificationMessage, setVerificationMessage] = useState('');
    const { token } = useParams();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
              //  console.log("we are inside the frontend verifying email");
                // Extract verification token from URL params
                //const token = match.params.token;
               
              //  console.log("eah el token el 7lwa de da", token);
                // Make a GET request to your backend API endpoint
                const response = await axios.get(`/api/story/verify-email/${token}`);
               // console.log("we are inside the frontend verifying email, ahe el egaba", response);

                // If the request is successful, set the verification message
                setVerificationMessage(response.data.message);
            } catch (error) {
                // If there's an error, set the verification message accordingly
                if (error.response) {
                    setVerificationMessage(error.response.data.message);
                } else {
                    setVerificationMessage('Error verifying email. Please try again later.');
                }
            }
        };

        // Call the verifyEmail function when the component mounts
        verifyEmail();
    }, [token]);

    return (
        <section id="notloggedinadjusterforsectionforappjs" class="section">
        <div>
                <h1> {translations[language].emailverification}</h1>
                <p>{verificationMessage}</p>
              <span>please login </span>  <a href="/LoginPage">here</a>
        </div>
        </section>
    );}

function VerifyPEmail() {

    const { language } = useContext(LanguageContext);
    const [verificationMessage, setVerificationMessage] = useState('');
    const { token } = useParams();

    //let useremailfromverification;

    useEffect(() => {
        const verifyPEmail = async () => {
            try {  
                // Make a GET request to your backend API endpoint
                const response = await axios.get(`/api/story/verify-email/${token}`);
             //   console.log("we are inside the frontend verifying email, ahe el egaba", response);

                // If the request is successful, set the verification message

                setVerificationMessage(response.data.message);
             //   console.log("inside the verifypemail,displaying the verification message", response.data.message);
               /* if (response.data.message === "Email verification successful") {
                    useremailfromverification = response.data.email;
                 //   console.log("checking if we got any email", useremailfromverification);
                    localStorage.setItem('email', useremailfromverification);
                 }

{{{{ this waws down there when pemail was different logic, now both will go through the same and user can upgrade through manage subscriptions page
verificationMessage === "Email verification successful" && (
                    <>
                        <span>Let's confirm your package </span>       <Link to={"/packagecheckout?isLoggedInFromVerifying=true"}>here</Link>

                    </>
                )}}}}

                  */
                    //       setIsLoggedInFromVerifying(true);
               //     tempvariable = true;
               
              
            } catch (error) {
                // If there's an error, set the verification message accordingly
                if (error.response) {
                    setVerificationMessage(error.response.data.message);
                } else {
                    setVerificationMessage('Error verifying email. Please try again later.');
                }
            }
        };

        // Call the verifyEmail function when the component mounts
        verifyPEmail();
    }, [token]);

   

    return (
        <section id="notloggedinadjusterforsectionforappjs"  class="section">
            <div>
                <h1>{translations[language].emailverification}</h1>
                <p>{verificationMessage}</p>
                <span>{translations[language].pleaselogin}  </span>  <a href="/LoginPage">{translations[language].here} </a>

                
            </div>
        </section>
    );
}



function LandingPage() {
    const { language } = useContext(LanguageContext);
    const selectedLanguage = localStorage.getItem('language'); // Retrieve from localStorage

    const textAlignStyle = selectedLanguage === 'ar' ? 'right' : 'center';
//    const textAlignStyletitle = selectedLanguage === 'ar' ? 'right' : 'center';


    const [openIndex, setOpenIndex] = useState(null);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: translations[language].safeContent,
            answer: translations[language].safeContentAnswer,
        },
        {
            question: translations[language].supportedDevices,
            answer: translations[language].supportedDevicesAnswer,
        },
        {
            question: translations[language].globalSupport,
            answer: translations[language].globalSupportAnswer,
        },
        {
            question: translations[language].freeTrial,
            answer: translations[language].freeTrialAnswer,
        },
        {
            question: translations[language].dataCollection,
            answer: translations[language].dataCollectionAnswer,
        },
        {
            question: translations[language].aiBooksCreativity,
            answer: translations[language].aiBooksCreativityAnswer,
        },
    ];

    return (
    
          
        <div >

            <section id="gwowhomepageadjuster">
            <div class="hero">

                <div id="textheadline">{translations[language].turnyourchildreality}</div>
                <div id="textheadlinesecond"> GWOW AI</div>

            </div>

            <div className="parent-container" >
                <div className="slideshow-container" >
                    <SlideShowLeft />
               
                </div>
                <div className="slideshow-container" >
                    <SlideShow />
                </div>
            </div>
           </section>
        

        <div class="subnav">
		   <div className="container">
			 <div id="subnavadjuster">
                        <h1 id="subnavtitle">GWOW AI</h1>
		   	</div>
			     <ul className="list">
                        <li className="item"> <a style={{ color: '#2c3e50', textDecoration: 'none !important' }} href="#section1">{translations[language].howitworks}</a></li>

                        <li className="item"> <a style={{ color: '#2c3e50', textDecoration: 'none !important' }} href="#section2">{translations[language].aboutus}</a></li>
                        <li className="item"> <a style={{ color: '#2c3e50', textDecoration: 'none !important' }} href="#section3">  {translations[language].whychoose} GWOW AI</a></li>

			    </ul>
	    	</div>
	    </div>
            <section id="section1" class="section">
                <h2>How it works</h2>
                <div>
                    <video width="100%" id="videostyle" controls >
                        <source src="AIStoriesVideo.mp4" type="video/mp4">
</source>
        </video>
    </div>
</section>


            <section id="section2" class="section">
                <h2>{translations[language].aboutus}</h2>
                <p id="aboutusfrontpagetext">{translations[language].ourplatformisdedicatedto}.</p>

                <div className= "cardcontainer">
                    <div class='card cardone'>
                        <div class='info' id='ipadadjustingforcard'>
                            <h1 class='title' id='cardheader'>{translations[language].ignitecreativity}</h1>
                            <p class='description'>{translations[language].empowerchildrenimagination}</p>
                    </div>
                </div>

                    <div class='card cardtwo'>
                        <div class='info' id='ipadadjustingforcard'>
                            <h1 class='title' id='cardheader'>{translations[language].connectondeeperlevel}</h1>
                            <p class='description'>{translations[language].fosteradeeperbond}</p>
                    </div>
                </div>

                    <div class='card cardthree'>
                        <div class='info' id='ipadadjustingforcard'>
                            <h1 class='title' id='cardheader'>{translations[language].funlearningadventures}</h1>
                            <p class='description'>{translations[language].leverageourservicestoprovide}</p>
                    </div>
                </div>
</div>

                 


            </section>

            <section id ="gwowsection">
            <div id="bodygwow">
                <div id="containergwow">
                    <div id="boxgwow">
                            <div class="imgBx specificcolorone firstboxgwowbackgroundcolor">
                                <div class="bgText">G</div>
                            </div>
                            <div class="content">
                                <div>
                                    <h2>Grow</h2>
                                    <p>Like seeds nurtured by sunlight and rain, every effort we make fosters growth and possibility, empowering us to reach our fullest potential
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="boxgwow">
                            <div class="imgBx  specificcolortwo secondboxgwowbackgroundcolor">
                                <div class="bgText">W</div>
                            </div>
                                <div class="content">
                                    <div>
                                        <h2>Wonder</h2>
                                    <p>Through eyes filled with wonder, we discover magic in every moment, fueling imagination and inspiring limitless possibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="boxgwow">
                            <div class="imgBx  specificcolorthree thirdboxgwowbackgroundcolor">
                                <div class="bgText">O</div>
                            </div>
                                    <div class="content">
                                        <div>
                                            <h2>Outshine</h2>
                                    <p>Just as each star in the sky radiates its own light, our unique talents and contributions illuminate the world, guiding others toward brighter horizons
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="boxgwow">
                            <div class="imgBx specificcolorfour fourthboxgwowbackgroundcolor">
                                <div class="bgText">W</div>
                            </div>
                                        <div class="content">
                                            <div>
                                    <h2>WOO</h2>
                                    <p> GWOW invites you to woo the world with kindness; nurturing bonds that inspire everyone to connect, dream big, and create magic together
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            


                </section>


            <section id="section3" class="section">
                <h2 style={{ fontSize: '3em', textAlign: 'center', paddingBottom: '32px' }}>  {translations[language].discoverwhyfamilies}</h2>
                <h2 id="familieschooseusheaders" style={{ fontSize: '1.7em', textAlign: textAlignStyle  }}>{translations[language].convenienceandsimplified} </h2>
                <p id="familieschooseusText" style={{ textAlign: textAlignStyle   }}>{translations[language].saygoodbuytothehassle}</p>
                <h2 id="familieschooseusheaders" style={{ fontSize: '1.7em', textAlign: textAlignStyle  }}>{translations[language].customizedlearningexperience}</h2>
                <p id="familieschooseusText" style={{ textAlign: textAlignStyle   }}>{translations[language].tailorstorytelling}</p>
                <h2 id="familieschooseusheaders" style={{ fontSize: '1.7em', textAlign: textAlignStyle  }}>{translations[language].parentalempowement} </h2>
                <p id="familieschooseusText" style={{  textAlign: textAlignStyle   }}>{translations[language].takeanactiverole}</p>
                <h2 id="familieschooseusheaders" style={{ fontSize: '1.7em', textAlign: textAlignStyle  }}>{translations[language].timesavingsolution}</h2>
                <p id="familieschooseusText" style={{  textAlign: textAlignStyle  }}>{translations[language].savetimebyeleminating}</p>
                <p style={{ paddingTop: '15px' }}>
                    {translations[language].experiencethemagic}
                    <a
                        style={{ boxShadow: 'inset 0 0 0 0 #ff8e3c', color: '#FFC800', padding: '0 .25rem', fontSize: '20px', fontWeight:'bold',  transition: 'color .3s ease-in-out, box-shadow .3s ease-in-out'}}
                        href="/PricingPage"
                        onMouseOver={(e) => { e.target.style.color = '#fff'; e.target.style.boxShadow = 'inset 200px 0 0 0 #ff8e3c'; }}
                        onMouseOut={(e) => { e.target.style.color = '#FFC800'; e.target.style.boxShadow = 'inset 0 0 0 0 #ff8e3c'; }}
                    >
                        {translations[language].pricingpage} 
                    </a>
                    {translations[language].formoreinformationandstart} 
                </p>
            </section>


            <section id="section4" class="section">
                <h2>{translations[language].frequentlyaskedquestion}</h2>
                {faqs.map((faq, index) => (
                    <div  key={index}>
                        <h4 id="frequentlyaskedheader" onClick={() => toggleAnswer(index)}>{faq.question}</h4>
               
                       
                        {openIndex === index && <p id={`answer-${index}`} style={{ fontSize: '18px' }}>{faq.answer}</p>}
                    </div>
                ))}
                <p style={{ textAlign: 'center', marginTop: '40px', fontSize: '2.1em', marginBottom: 'initial'}}>
                    {translations[language].learnmore}{' '}
                 
                    <Link style={{ color: '#2c3e50' }} to="/learnmore">
                    {translations[language].here}
                </Link>
                </p>
               </section>
</div>
          
      
         );

}




function LoginPage({ onLogin } ) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
   // console.log("this is the is loggedin passed to the login page ", isLoggedIn);
    const navigate = useNavigate();

    const { language } = useContext(LanguageContext);

   // console.log("this is the type pf onling in app ", typeof onLogin);

    const handleInput = (event, setter,  isEmail = false) => {
        const value = isEmail ? event.target.value.toLowerCase() : event.target.value;
        setter(value);
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
      //  console.log("we are inside the handle submit");

        // Check if email and password are filled
        if (email && password) {
            // Example POST request to login user
            fetch('/api/story/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            })
                .then((response) => {
                    // Handle response from backend
                    if (response.ok) {

                        response.json().then(data => {
                            const token = data.token; // Assuming the token is sent back in the response data

                    //    console.log("this is the response we get for the token", response);


                      //  const token = response.data.token; // Assuming the token is sent back in the response
                        // Store the received JWT token securely in localStorage



                            if (data.isBlocked) {
                                // Redirect to BlockedAccount page
                                navigate('/blockedAccount');
                            } else {
                                localStorage.setItem('token', token);
                             //   console.log("this is the token itself", token);

                                // Login successful
                                // Redirect user or display a success message
                                setLoginMessage("Login successful. Redirecting...");
                                // setIsLoggedIn(true); // Set isLoggedIn to true
                                onLogin();
                                setTimeout(() => {

                                    navigate('/Dashboard');

                                }, 2000); // Redirect after 2 seconds (adjust as needed)
                            }
                        });
                        } else {
                        // Login failed
                        // Display error message to the user
                        setLoginMessage("Login failed. Please check your credentials and try again.");
                    }
                })
                .catch((error) => {
                    console.error('Error logging in:', error);
                    setLoginMessage("Error logging in. Please try again later.");
                });
        } else {
            setLoginMessage("Login failed. Please enter your email and password.");
        }
    };

    return (
        <section id="loginpagesection" class="section">
          
                <form onSubmit={handleSubmit}>
                <h3 id="loginpagesectionheader">{translations[language].loginpage}</h3>
                    <div  style={{ paddingTop: '20px' }}>
                        <div class="loginform">
                        <div class="subtitle">{translations[language].welcomebackpleaselogin}</div>
                            <div class="input-container ic2">
                                <input id="email" class="input" type="text" placeholder=" " value={email}
                                    onChange={(event) => handleInput(event, setEmail, true)}
                                />
                            <label for="email" class="placeholder">{translations[language].email}</label>
                            </div>
                            <div className="input-container ic2">
                                <input id="password" className="input" type="password" placeholder=" " value={password}
                                    onChange={(event) => handleInput(event, setPassword)}
                                />
                                <label htmlFor="password" className="placeholder">
                                {translations[language].password}
                                </label>
                            </div>
                        <button type="submit" class="submit">{translations[language].submit}</button>
                        <div class="subtitle">   <span>{translations[language].donthaveanouaccount}<a id="refcolor" href="/SignUpPage">{translations[language].here}</a></span></div>
                        <div class="subtitle">   <span>{translations[language].forgotyourpassword}<a id="refcolor" href="/initiateresetpassowrd">{translations[language].here}</a></span></div>
                        </div>
                    </div>
                </form>
         
            <div>
                {loginMessage && <div className="success-messagelogin">{loginMessage}</div>}
            </div>
        </section>
    );
}


function SignUpPageFree() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [registrationMessage, setRegistrationMessage] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state variable
   // const navigate = useNavigate();

    const { language } = useContext(LanguageContext);
    const handleInput = (event, setter, isEmail = false) => {
        const value = isEmail ? event.target.value.toLowerCase() : event.target.value;
        setter(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
     //   console.log("we are inside the handle submit");
        // Check if all fields are filled and terms are checked
        if (firstName && lastName && email && password && termsChecked) {
          

            // Example POST request to register user
            fetch('/api/story/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    password,
                }),
            })
                .then((response) => {
                    // Handle response from backend
                    if (response.ok) {
                        // Registration successful
                        // Redirect user to verification page or display a success message
                        setRegistrationMessage("Registration successful. Please check your email for verification.");
                        setRegistrationSuccess(true); // Set registration success to true
                      


                    } else {
                        // Registration failed
                        // Display error message to the user
                        return response.json().then((data) => {
                            // Display error message to the user
                            console.error('Error registering user:', data.message);
                            setRegistrationMessage(data.message);
                            setRegistrationSuccess(false);
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error registering user:', error);
                    setRegistrationMessage("Error registering user. Please try again later.");
               });
        } else {
            setRegistrationMessage("Registration failed. Please make sure to include all fields, accept the terms and conditions and try again.");
        // Display error message indicating missing fields or unchecked terms
        }
    };

    const openTermsPopup = () => {
        // Open a pop-up window to display terms and conditions
        window.open('/terms-and-conditions', 'Terms and Conditions', 'width=1000,height=700');
    };
    return (


        <section id="signuppagesection" class="section">
            {!registrationSuccess && ( // Conditionally render the form based on registration success

            <form onSubmit={handleSubmit}>
                    <h3 id="signuppagesectionheader">{translations[language].signup}</h3>
            <div  style={{ paddingTop: '20px' }}>

                <div id="signupformadjuster" class="form">
                            <div class="signuptitle">{translations[language].welcome}</div>
                            <div class="subtitle">{translations[language].letscreateyouraccount}</div>
                    <div class="input-container ic1">
                        <input id="firstname" class="input" type="text" placeholder=" " value={firstName}
                            onChange={(event) => handleInput(event, setFirstName)}
                        />
                        {/* <div class="cut"></div> */}
                                <label for="firstname" class="placeholder">{translations[language].firstname}</label>
                    </div>
                    <div class="input-container ic2">
                        <input id="lastname" class="input" type="text" placeholder=" " value={lastName}
                            onChange={(event) => handleInput(event, setLastName)}
                        />
                        {/* <div class="cut"></div> */}
                                <label for="lastname" class="placeholder">{translations[language].lastname}</label>
                    </div>
                    <div class="input-container ic2">
                        <input id="email" class="input" type="text" placeholder=" " value={email}
                            onChange={(event) => handleInput(event, setEmail, true)}
                        />
                        {/* <div class="cut cut-short"></div> */}
                                <label for="email" class="placeholder">{translations[language].email}</label>
                    </div>
                    <div className="input-container ic2">
                        <input id="password" className="input" type="password" placeholder=" " value={password}
                            onChange={(event) => handleInput(event, setPassword)}
                        />
                        <label htmlFor="password" className="placeholder">
                                    {translations[language].password}
                        </label>
                    </div>
                    <div style={{ paddingTop:'20px' }} className="terms-checkbox">
                        <input type="checkbox" id="terms" checked={termsChecked}
                            onChange={() => setTermsChecked(!termsChecked)}
                        />
                        <label htmlFor="terms">
                            I agree to the{' '}
                            <span className="terms-link" onClick={openTermsPopup}>
                                        {translations[language].termsandconditions} 
                            </span>
                        </label>
                    </div>
                            <button type="submit" class="submit">{translations[language].submit}</button>
                       
                </div>


                </div>
                </form>
                

            )}
            <div>
                {registrationMessage && <div className="success-messagelogin">{registrationMessage}</div>}
            </div>
                </section>
    );
}
   
function SignUpPagePaid() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [registrationMessage, setRegistrationMessage] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state variable
   // const navigate = useNavigate();
    const { language } = useContext(LanguageContext);

    const handleInput = (event, setter, isEmail = false) => {
        const value = isEmail ? event.target.value.toLowerCase() : event.target.value;
        setter(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
      //  console.log("we are inside the handle submit");
        // Check if all fields are filled and terms are checked
        if (firstName && lastName && email && password && termsChecked) {


            // Example POST request to register user
            fetch('/api/story/signuppaid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    password,
                }),
            })
                .then((response) => {
                    // Handle response from backend
                    if (response.ok) {
                        // Registration successful
                        // Redirect user to verification page or display a success message
                        setRegistrationMessage("Registration successful. Please check your email for verification.");
                        setRegistrationSuccess(true); // Set registration success to true



                    } else {
                        // Registration failed
                        // Display error message to the user
                        setRegistrationMessage("Registration failed. Please try again later.");
                    }
                })
                .catch((error) => {
                    console.error('Error registering user:', error);
                    setRegistrationMessage("Error registering user. Please try again later.");
                });
        } else {
            setRegistrationMessage("Registration failed. Please make sure to include all fields, accept the terms and conditions and try again.");
            // Display error message indicating missing fields or unchecked terms
        }
    };

    const openTermsPopup = () => {
        // Open a pop-up window to display terms and conditions
        window.open('/terms-and-conditions', 'Terms and Conditions', 'width=1000,height=700');
    };
    return (


        <section id="section2" class="section">
            {!registrationSuccess && ( // Conditionally render the form based on registration success

                <form onSubmit={handleSubmit}>
                    <h3>{translations[language].signup}</h3>
                    <div style={{ paddingTop: '20px' }}>

                        <div class="form">
                            <div class="signuptitle">{translations[language].welcome}</div>
                            <div class="subtitle">{translations[language].letscreateyouraccount}</div>
                            <div class="input-container ic1">
                                <input id="firstname" class="input" type="text" placeholder=" " value={firstName}
                                    onChange={(event) => handleInput(event, setFirstName)}
                                />
                                {/* <div class="cut"></div> */}
                                <label for="firstname" class="placeholder">{translations[language].firstname}</label>
                            </div>
                            <div class="input-container ic2">
                                <input id="lastname" class="input" type="text" placeholder=" " value={lastName}
                                    onChange={(event) => handleInput(event, setLastName)}
                                />
                                {/* <div class="cut"></div> */}
                                <label for="lastname" class="placeholder">{translations[language].lastname}</label>
                            </div>
                            <div class="input-container ic2">
                                <input id="email" class="input" type="text" placeholder=" " value={email}
                                    onChange={(event) => handleInput(event, setEmail, true)}
                                />
                                {/* <div class="cut cut-short"></div> */}
                                <label for="email" class="placeholder">{translations[language].email}</label>
                            </div>
                            <div className="input-container ic2">
                                <input id="password" className="input" type="password" placeholder=" " value={password}
                                    onChange={(event) => handleInput(event, setPassword)}
                                />
                                <label htmlFor="password" className="placeholder">
                                    {translations[language].password} 
                                </label>
                            </div>
                            <div style={{ paddingTop: '20px' }} className="terms-checkbox">
                                <input type="checkbox" id="terms" checked={termsChecked}
                                    onChange={() => setTermsChecked(!termsChecked)}
                                />
                                <label htmlFor="terms">
                                    I agree to the{' '}
                                    <span className="terms-link" onClick={openTermsPopup}>
                                        {translations[language].termsandconditions}
                                    </span>
                                </label>
                            </div>
                            <button type="submit" class="submit">{translations[language].submit}</button>

                        </div>


                    </div>
                </form>


            )}
            <div>
                {registrationMessage && <div className="success-messagelogin">{registrationMessage}</div>}
            </div>
        </section>
    );

}


function PricingPage() {

    const { language } = useContext(LanguageContext);
    const selectedLanguage = localStorage.getItem('language'); // Retrieve from localStorage


    const pageStyle = {};
    if (selectedLanguage === 'ar') {
        pageStyle.direction = 'rtl';
        
    }
    const ulStyle = selectedLanguage === 'ar' ? { direction: 'rtl', textAlign: 'right' } : {};

    return (
        <div style={pageStyle}>
            <div >
                <h4 className="forallpaidpacakges">

                    {translations[language].forallpaidpackages}
                
                    <span
                        style={{
                            boxShadow: 'inset 0 0 0 0 #2ca7bb',
                            color: '#2ca7bb',
                            padding: '0 .25rem',
                            fontSize: '26px',
                            fontWeight: 'bold',
                            transition: 'color .3s ease-in-out, box-shadow .3s ease-in-out',
                            cursor: 'pointer'
                        }}
                        onMouseOver={(e) => {
                            e.target.style.color = '#fff';
                            e.target.style.boxShadow = 'inset 200px 0 0 0 #2ca7bb';
                        }}
                        onMouseOut={(e) => {
                            e.target.style.color = '#2ca7bb';
                            e.target.style.boxShadow = 'inset 0 0 0 0 #2ca7bb';
                        }}
                        onClick={() => {
                            window.location.href = '/PricingPage'; // Redirects to the PricingPage when clicked
                        }}
                    >
                        {translations[language].onemonthfree}
                    </span>

                  
                </h4>
                <h4 className="forallpaidpacakges">
                {translations[language].availablecurrences}</h4>
            </div>

        <div class="wrapper" id='adjustingpricingwrapper'>
                <div id= "gprice-singlehomepage" class="pricing-table gprice-single">
            <div class="head">
                        <h4 class="title">{translations[language].free}</h4>
            </div>
            <div class="content">
                <div class="price">
                    <h1>$0</h1>
                </div>
                <ul>
                         
                            <li style={ulStyle}>{translations[language].accesstogenerallibrary}</li>
                        
                            <li style={{ marginTop: '40px', ...ulStyle }}>{translations[language].earlyaccesstonewfeature}</li>

                            <li style={ulStyle}> {translations[language].nocreditcardrequired}</li>

                </ul>
                <div class="sign-up">
                            <a href="/SignUpPage" class="btn bordered radius">{translations[language].signupnow}</a>
                </div>
            </div>
        </div>
                <div id="gprice-singlehomepage"  class="pricing-table gprice-single">
            <div class="head">
                <h4 class="title">Quest</h4>
            </div>
            <div class="content">
                <div class="price">
                    <h1>$7.99</h1>
                </div>
                <ul>
                            <li style={ulStyle}>{translations[language].generalandprivatelibraries}</li>
                            <li style={ulStyle}>{translations[language].questbook}</li>
                            <li style={ulStyle}>{translations[language].downloadbookfeature}</li>
                            
                    

                </ul>
                <div class="sign-up">
                            
                            <a href="/SignUpPagePaid" class="btn bordered radius">{translations[language].signupnow}</a>
                </div>
            </div>
        </div>
                <div id="gprice-singlehomepage"  class="pricing-table gprice-single">
            <div class="head">
                <h4 class="title">Odyssey</h4>
            </div>
            <div class="content">
                <div class="price">
                    <h1>$14.99</h1>
                </div>
                <ul>
                            <li style={ulStyle}>{translations[language].generalandprivatelibraries}</li>
                            <li style={ulStyle}>{translations[language].odysseybook}</li>
                            <li style={ulStyle}>{translations[language].downloadbookfeature}</li>


                </ul>
                <div class="sign-up">
                            <a href="/SignUpPagePaid" class="btn bordered radius">{translations[language].signupnow}</a>
                </div>
            </div>
        </div>
                <div id="gprice-singlehomepage"  class="pricing-table gprice-single">
            <div class="head">
                <h4 class="title">Enchanted</h4>
            </div>
            <div class="content">
                <div class="price">
                    <h1>$26.99</h1>
                </div>
                <ul>
                            <li style={ulStyle}>{translations[language].generalandprivatelibraries}</li>
                            <li style={ulStyle}>{translations[language].enchantedbook}</li>
                            <li style={ulStyle}>{translations[language].downloadbookfeature}</li>
                </ul>
                <div class="sign-up">
                            <a href="/SignUpPagePaid" class="btn bordered radius">{translations[language].signupnow}</a>
                </div>
            </div>
        </div>
  
            </div>
          



        </div>

    );

}


/*
function Home() {
    const navigate = useNavigate();
    const [showStoryForm, setShowStoryForm] = useState(false);
    const [showLibraryForm, setShowLibraryForm] = useState(false);

    const handleCreateNewStory = () => {
        setShowStoryForm(true);
        setShowLibraryForm(false);
        navigate('/story-form');
    };

    const handleViewLibrary = () => {
        setShowStoryForm(false);
        setShowLibraryForm(true);
        navigate('/library-form');
    };

    return (
        <div >

            {!showStoryForm && !showLibraryForm && (
                <div >
                    <button onClick={handleCreateNewStory}>Create New Story</button>
                    <button onClick={handleViewLibrary}>View Library</button>
                </div>
            )}
            {showStoryForm && <StoryForm />}
            {showLibraryForm && <LibraryForm />}


        </div>
    );
}
*/


export default App;
