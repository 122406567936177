import React, {  useContext } from 'react';
//import axios from 'axios'; // Import axios for making API calls
import './TermsAndConditions.css'; // Import custom CSS for styling
//import { Link } from 'react-router-dom';
import translations from './translations';
import { LanguageProvider, LanguageContext } from './LanguageContext';

function TermsAndConditions() {
    const { language } = useContext(LanguageContext);
    return (
        <div id="bodycss">
            <div id="terms-container">
                <h1 id="terms-header">Terms and Conditions</h1>
                <div id="terms-section">
                    <h2 id="terms-subheader">1. Introduction</h2>
                    <p id="terms-paragraph">1.1. These Terms and Conditions ("Terms") govern the use of the website and services ("Services") provided by <span id="terms-company-name">Futurewave AI LLC</span> ("Company"), accessible at <span id="terms-website-url">[www.GwowAI.com]</span>.</p>
                    <p id="terms-paragraph">1.2. By accessing or using the Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Services.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">2. Subscription Policy</h2>
                    <p id="terms-paragraph">2.1. When you subscribe to our services on a monthly basis, you will be charged monthly until you cancel your subscription. You can cancel your subscription for the next billing cycle at any time from your profile. If you opt for a yearly subscription, you will be charged on a yearly basis.</p>
                    <p id="terms-paragraph">2.2. Users can upgrade or downgrade their subscription plan at any time. The process for making such changes will be provided on the subscription management page. 2.3. Prohibited Conduct: Any attempt to misuse or exploit the subscription services, including but not limited to sharing account credentials or engaging in fraudulent activities, is strictly prohibited and may result in suspension or termination of your account.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">3. Termination</h2>
                    <p id="terms-paragraph">3.1. We reserve the right to terminate your access to our Website, without notice, for any reason, including but not limited to a violation of these Terms.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">4. Disclaimers and Limitation of Liability</h2>
                    <p id="terms-paragraph">4.1. Our Website is provided on an �as is� and �as available� basis. We do not warrant that our Website will be uninterrupted, error-free, or secure. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</p>
                    <p id="terms-paragraph">4.2. In no event will we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our Website, even if we have been advised of the possibility of such damages.</p>
                    <p id="terms-paragraph">4.3. Data Loss Disclaimer: We are not responsible for any loss of data. Users are strongly advised to download and securely store their information and generated content offline. It is the user's responsibility to ensure that they have adequate backups of their data.</p>
                    <p id="terms-paragraph">4.4. {translations[language].languagedisclaimer}</p>
                    <p id="terms-paragraph">4.5. Refunds or cancellations of subscriptions are at the Company's sole discretion.</p>
                    <p id="terms-paragraph">4.6 Limitation of Liability Regarding Reseller: FutureWave AI LLC sells its products through an authorized reseller. The Company is not liable for any issues arising from transactions conducted by this reseller, including, but not limited to, the handling of payment information and any data breaches. Users agree that FutureWave AI LLC shall not be held responsible for any claims, damages, or liabilities resulting from actions or omissions of the reseller.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">5. Ownership</h2>
                    <p id="terms-paragraph">5.1. You may provide input to the App (Input), and receive output generated by the App based on the Input (Output). Between you and <span id="terms-company-name">FutureWave AI LLC</span> and in accordance with the applicable law, you own all input. You are solely responsible for the creation of the Output and ensuring that the Output complies with our Terms. Subject to compliance with these terms, <span id="terms-company-name">FutureWave AI LLC</span> assigns to you all rights to the Output. <span id="terms-company-name">FutureWave AI LLC</span> and its successors also have the non-exclusive, perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, and distribute but not modify any Output for promotional and marketing purposes. This license survives termination or expiration of the Terms.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">6. Payment</h2>
                    <p id="terms-paragraph">6.1. You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to charge your chosen payment provider for any such amounts upon making your purchase. If your purchase is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until you notify us of your cancellation.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">7. Pricing and Order</h2>
                    <p id="terms-paragraph">7.1. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We also reserve the right to refuse any order placed through the Site.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">8. Indemnification</h2>
                    <p id="terms-paragraph">8.1. You agree to indemnify and hold the Company harmless from any claims, damages, losses, or liabilities arising from your use of the Services or violation of these Terms.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">9. Liability Limitation</h2>
                    <p id="terms-paragraph">9.1. We will not be liable for indirect, punitive, incidental, special, consequential, or exemplary damages, or for loss of profits, goodwill, or data, or other intangible losses, that result from the use of, or inability to use, our Services or any other aspect of this Agreement.</p>
                    <p id="terms-paragraph">9.2. Our aggregate liability to you for all losses or damages arising out of this Agreement or your use of our Services will not exceed the greater of the fees we received from you for our Services that are subject to the claim during the three-month period immediately preceding the date on which the damages arose and USD$100.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">10. Termination and Account Suspension</h2>
                    <p id="terms-paragraph">10.1. Your account may be terminated or suspended if we reasonably believe that you have violated any of these Terms or if you have violated our guidelines on how to use our services indicated in our terms and services.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">11. Third-Party API Disclaimer</h2>
                    <p id="terms-paragraph">11.1. The Service uses a third-party API to create both AI-generated stories through text and motion from still portrait image(s) of the user. <span id="terms-company-name">FutureWave AI LLC</span> does not control and is not responsible for the API or the results it produces.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">12. Governing Law and Jurisdiction</h2>
                    <p id="terms-paragraph">12.1. These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law provisions. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in the State of Florida.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">13. Third-Party Services and Payment</h2>
                    <p id="terms-paragraph">13.1. The Company utilizes a third-party payment gateway, FastSpring, to handle all financial transactions. By making a purchase through the Services, you agree to adhere to FastSpring's terms and conditions for payment processing. You will be directed to their portal where you can review their terms and services and finalize payments.</p>
                    <p id="terms-paragraph">13.2. The Company disclaims any liability for breaches, leaks, or issues arising from the use of FastSpring's services. Users are solely responsible for reviewing and complying with FastSpring's terms and conditions.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">14. Dispute Resolution</h2>
                    <p id="terms-paragraph">14.1. Arbitration Agreement: By using our Services, you agree to resolve any disputes through binding arbitration rather than in court. The Federal Arbitration Act and federal arbitration law apply to these Terms. This arbitration provision shall survive termination of these Terms.</p>
                    <p id="terms-paragraph">14.2. Exceptions to Arbitration: You may assert claims in small claims court if your claims qualify. In addition, any claims related to intellectual property rights, or claims of unauthorized use or piracy, will not be subject to arbitration.</p>
                    <p id="terms-paragraph">14.3. Arbitration Procedures: The American Arbitration Association (AAA) will administer the arbitration under its rules, which are available at www.adr.org. Payment of all filing, administration, and arbitrator fees will be governed by the AAA�s rules. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">15. Security and Acceptable Use</h2>
                    <p id="terms-paragraph">15.1. Security: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify <span id="terms-company-name">FutureWave AI LLC</span> of any unauthorized use or suspected security breaches.</p>
                    <p id="terms-paragraph">15.2. Acceptable Use: Users agree to use the Services in compliance with all applicable laws and regulations. Prohibited activities include but are not limited to: (a) unauthorized access to or use of the Services or any content therein, (b) engaging in any activity that disrupts, interferes with, or harms the functioning of the Services, (c) transmitting any harmful code, viruses, or malicious software, (d) engaging in any unlawful, fraudulent, or abusive behavior.</p>
                    <p id="terms-paragraph">15.3. Consequences of Violation: <span id="terms-company-name">FutureWave AI LLC</span> reserves the right to suspend or terminate user accounts that violate these Terms or engage in prohibited activities. Suspension or termination may occur without prior notice, and <span id="terms-company-name">FutureWave AI LLC</span> will not be liable for any damages or losses resulting from such actions.</p>
                    <p id="terms-paragraph">15.4. Users are responsible for all content they upload, post, or otherwise transmit via the Services and must ensure it does not violate any laws or the rights of third parties.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">16. Privacy Policy</h2>
                    <p id="terms-paragraph">16.1. Collection of Personal Information: We collect personal information that you voluntarily provide to us when you register on our Website, express an interest in obtaining information about us or our products and services, participate in activities on the Website, or otherwise contact us.</p>
                    <p id="terms-paragraph">16.2. Use of Personal Information: The personal information we collect is used for the following purposes:</p>
                    <ul id="terms-list">
                        <li id="terms-list-item">To provide, operate, and maintain our Services.</li>
                        <li id="terms-list-item">To improve, personalize, and expand our Services.</li>
                        <li id="terms-list-item">To understand and analyze how you use our Services.</li>
                        <li id="terms-list-item">To develop new products, services, features, and functionality.</li>
                        <li id="terms-list-item">To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Services, and for marketing and promotional purposes.</li>
                        <li id="terms-list-item">To process your transactions and manage your orders.</li>
                        <li id="terms-list-item">To send you emails and notifications.</li>
                        <li id="terms-list-item">To find and prevent fraud.</li>
                    </ul>
                    <p id="terms-paragraph">16.3. Disclosure of Personal Information: We may share your personal information in the following situations:</p>
                    <ul id="terms-list">
                        <li id="terms-list-item">With service providers who perform services on our behalf.</li>
                        <li id="terms-list-item">In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process.</li>
                        <li id="terms-list-item">If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of our Company or others.</li>
                        <li id="terms-list-item">With your consent or at your direction.</li>
                    </ul>
                    <p id="terms-paragraph">16.4. 16.4. Data Security: We employ various security measures, including administrative, technical, and physical safeguards, to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the security of our systems or the methods of data transmission. You are encouraged to take your own measures to protect your data.</p>
                    <p id="terms-paragraph">16.5. Children�s Privacy: Our Services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us with personal information, please contact us.</p>
                    <p id="terms-paragraph">16.6. International Data Transfers: Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country. We take appropriate measures to ensure that your personal data remains protected in accordance with this Privacy Policy.</p>
                    <p id="terms-paragraph">16.7. Changes to This Privacy Policy: We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our Website. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">17. User Responsibilities</h2>
                    <p id="terms-paragraph">17.1. The user is granted ownership of the AI-generated content for personal and non-commercial use. This ownership does not extend to rights for commercial exploitation, including resale, licensing, or distribution of the content. Any commercial use, including but not limited to resale, is prohibited unless expressly authorized in writing by FutureWave AI LLC. Any violation of this clause may result in the termination of the user's rights to the content and legal action.</p>
                    <p id="terms-paragraph">17.2. Data Deletion Rights: You have the right to request the deletion of your personal data after canceling subscription. To exercise this right, please contact our support team. We will make reasonable efforts to delete your data promptly, subject to any legal obligations we may have to retain it. </p>

                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">18. Use of Cookies</h2>
                    <p id="terms-paragraph">18.1. We use �cookies� to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.</p>
                </div>

                <div id="terms-section">

                    <h2 id="terms-subheader">19. Miscellaneous</h2>
                    <p id="terms-paragraph">19.1. These Terms represent the complete agreement between you and <span id="terms-company-name">FutureWave AI LLC</span> regarding your use of our Services, replacing any previous agreements or understandings.</p>
                    <p id="terms-paragraph">19.2. If any provision of these Terms is found to be unenforceable or invalid by a court of competent jurisdiction, the remaining provisions of these Terms will continue to be in effect.</p>
                    <p id="terms-paragraph">19.3. Acceptance of Terms and Future Updates "By clicking 'Agree' to these Terms and Conditions, you acknowledge that you are entering into a legally binding contract with Futurewave AI LLC. You further agree that these Terms may be updated or modified at our discretion, and your continued use of the Services constitutes acceptance of the most current version of these Terms. We encourage you to check for updates regularly. In the event of any dispute, the version of the Terms in effect at the time of the dispute shall govern."</p>



                </div>

                <div id="terms-section">
                    <h2 id="terms-subheader">20. Refund Policy</h2>
                    <p id="terms-paragraph">20.1. Subscription Plans</p>
                    <p id="terms-paragraph">Our service offers various subscription packages based on the number of storybooks you can create. By subscribing, you agree to pay the subscription fees as described during the signup process.</p>

                    <p id="terms-paragraph">20.2. Refund Eligibility</p>
                    <p id="terms-paragraph">
                        <strong>30-Day Money-Back Guarantee:</strong> For new subscribers, we offer a 30-day money-back guarantee. If you are not satisfied with our service within the first 30 days of your initial subscription, you may request a full refund of the subscription fee, provided that you have not utilized more than [15%] of your allotted credits.
                    </p>
                    <p id="terms-paragraph">
                        <strong>Usage Limitations:</strong> Refunds are available only if you have used a minimal portion of your subscription credits. Specifically, if you have created fewer than [5] stories or consumed less than [15%] of your credits, you may be eligible for a refund. This is to prevent misuse of the subscription service.
                    </p>
                    <p id="terms-paragraph">
                        <strong>Pro-Rated Refunds:</strong> Refunds are not available for partial months or remaining days of the subscription period. If you cancel after the 30-day money-back guarantee period, no pro-rated refunds will be provided.
                    </p>
                    <p id="terms-paragraph">
                        <strong>Renewals:</strong> Subscription renewals are non-refundable. If you cancel your subscription before the end of your current billing cycle, you will have access to the service until the end of that cycle, but no refund will be provided.
                    </p>

                    <p id="terms-paragraph">20.3. Refund Request Process</p>
                    <p id="terms-paragraph">
                        To request a refund, please contact our customer support team at [support@GwowAI.com] within the applicable refund period. Provide your subscription details and reason for requesting a refund. Refund requests will be reviewed and processed within [5-7] business days. Your usage of credits will be assessed during this process.
                    </p>

                    <p id="terms-paragraph">20.4. Exceptions</p>
                    <p id="terms-paragraph">
                        <strong>Non-Refundable Fees:</strong> Some fees or charges related to specific features or services may be non-refundable, as specified during the purchase or in your account settings.
                    </p>
                    <p id="terms-paragraph">
                        <strong>Violation of Terms:</strong> Refunds will not be granted if the account is found to be in violation of our Terms of Service, including but not limited to abuse, fraud, or other prohibited activities.
                    </p>

                    <p id="terms-paragraph">20.5. Changes to the Refund Policy</p>
                    <p id="terms-paragraph">
                        We reserve the right to amend or update this refund policy at our discretion. Any changes will be posted on our website, and you are encouraged to review the policy periodically.
                    </p>

                    <p id="terms-paragraph">20.6. Contact Us</p>
                    <p id="terms-paragraph">
                        For any questions or concerns regarding our refund policy, please contact us at [support@GwowAI.com].
                    </p>
                </div>



            </div>
            </div>
        
        );

}

export default TermsAndConditions;